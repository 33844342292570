import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from "yup";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  email: string
  recoveryLinkSent: boolean
  apiError: string | null;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  forgotPasswordAccountId: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      email: '',
      recoveryLinkSent: false,
      apiError: '',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (
      apiRequestCallId &&
      responseJson &&
      apiRequestCallId === this.forgotPasswordAccountId
    ) {
      if (responseJson?.errors && responseJson?.errors?.length !== 0) {
        const errorMessage = "Account not found.";
        this.setState({
            email: '',
          apiError: errorMessage,
        })
      }
      else {
        this.handleRecoveryLinkSent();
        this.setState({ apiError: null , email:''});
      } 
    }
      // Customizable Area End
    }

    // Customizable Area Start  
    forgotPasswordSchema = Yup.object({
      email: Yup.string()
        .email("Invalid e-mail")
        .required("Please enter your email address"),
    });

    submitValidateAccount = (email: string) => {
      let token = localStorage.getItem("token");
      const formdata = new FormData();
      formdata.append('email', email);

      this.setState({
        email: email,
      })

      const header = {
        token: token
      };

      const requestMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.forgotPasswordAccountId = requestMsg.messageId;

      requestMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_forgot_password/forgot_password`
      );

      requestMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMsg.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
      );

      requestMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );

      runEngine.sendMessage(requestMsg.id, requestMsg);
      return true
    }

    handleNavigateLoginBtn = () => {
      this.props.navigation.navigate("Login")
    }

    backArraowBtn = () => {
      this.props.navigation.navigate("Login")
    }

    handleRecoveryLinkSent = () => {
      this.setState({
        recoveryLinkSent: !this.state.recoveryLinkSent
      });
    };
    // Customizable Area End
  }