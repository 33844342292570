import React from "react";

// Customizable Area Start
import { Box, Breadcrumbs, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { BackArrowBlack, download, masterCard, visaLogo } from "./assets";
import moment from "moment";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Footer from "../../../components/src/Footer.web";
import HeaderDropdown from "../../../components/src/HeaderDropdown.web";

import PaymentHistoryController, {
    Props,
} from "./PaymentHistoryController.web";
// Customizable Area End


export default class PaymentHistory extends PaymentHistoryController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <PaymentHistoryStyle>
                <HeaderDropdown data-test-id="headerDiv" editProfileClick={this.profileClickButton} subscriptionClick={this.subscriptionClickButton} paymentClick={this.paymentClickBotton} />
                <Box style={{ padding: '30px', marginLeft: '20px', marginRight: '20px' }}>
                    <Breadcrumbs style={{ marginLeft: '64px' }} separator={<NavigateNextIcon fontSize="small" />}>
                        <Button className="breadcrumbsButton" color="inherit" data-test-id="breadcrumbsButton">
                            Settings
                        </Button>
                        <Button className="breadcrumbsButton" color="inherit" data-test-id="breadcrumbsButton" onClick={this.handleSubscription}>
                            Subscription
                        </Button>
                        <Typography className="breadcrumbsText">
                            Payments history
                        </Typography>
                    </Breadcrumbs>
                    <Box style={{ display: 'flex', marginTop: '10px' }}>
                        <Button className="backButton" data-test-id="backButton" onClick={this.handleSubscription}>
                            <img style={{ color: 'black' }} src={BackArrowBlack} alt="Back" />
                        </Button>
                        <Typography className="titleTypography">Payment history</Typography>
                    </Box>
                </Box>
                <Box>
                    <Box className="lowerBox">
                        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography className="reviewTextTitle">Review your billing history and manage your invoices. </Typography>
                            <Button className="downloadBtn" data-test-id="downloadBtn">
                                <img style={{ color: 'white' }} src={download} />
                                <Typography className="btnTitle" onClick={this.handleDownload}>Download all</Typography>
                            </Button>
                        </Box>
                        <div ref={this.targetRef}>
                            <TableContainer style={{ margin: '20px 0', borderRadius: '8px', border: "1px solid #E7E5E4", marginTop: '30px' }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="tableTitle">Order number</TableCell>
                                            <TableCell className="tableTitle">Amount</TableCell>
                                            <TableCell className="tableTitle">Type of Plan</TableCell>
                                            <TableCell className="tableTitle">Payment date</TableCell>
                                            <TableCell className="tableTitle">Payment method</TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.subscriptionDetails.map((subscription: any) => (
                                            <TableRow key={subscription.stripe_subscription_id}>
                                                <TableCell className="tableDesText">{subscription.stripe_subscription_id}</TableCell>
                                                <TableCell className="tableDesText">{subscription.amount}</TableCell>
                                                <TableCell className="tableDesText">{subscription.plan_name}</TableCell>
                                                <TableCell className="tableDesText">{moment(subscription.start_date).format('MMM DD, YYYY')}</TableCell>
                                                <TableCell><img src={visaLogo} alt="Visa" style={{ height: '13px', marginRight: '10px' }} /> **** {subscription.cardNumber}</TableCell>
                                                <TableCell><Button className="seeDetailsBtn" onClick={this.handleSeeDetails}>See Details</Button></TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </Box>
                </Box>
                <Footer />

            </PaymentHistoryStyle >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const PaymentHistoryStyle = styled("div")({
    "& .headerBox": {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '20px',
        borderBottom: '1px solid #E7E5E4'
    },
    "& .profileBox": {
        display: 'flex'
    },
    "& .breadcrumbsButton": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "22px",
        color: "#57534E",
        "& .MuiButton-label": {
            textTransform: "none",
        },
    },
    "& .breadcrumbsText": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "22px",
        color: "#1C2110",
    },
    "& .titleTypography": {
        fontWeight: 700,
        fontFamily: "Poppins",
        lineHeight: "40px",
        fontSize: "30px",
        color: "#1C2110",
        letterSpacing: "-0.5%",
    },
    "& .tableTitle": {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "22px",
        color: "#1C2110",
    },
    "& .tableDesText": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "18px",
        color: "#1C2110",
    },
    "& .seeDetailsBtn": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "18px",
        color: "#557C29",
        "& .MuiButton-label": {
            textTransform: 'none'
        },
    },
    "& .lowerBox": {
        marginLeft: '15%',
        marginRight: '15%',
        borderRadius: '8px 8px 32px 8px',
        padding: '40px',
        boxShadow: "0 0 10px 5px rgba(0, 0, 0, 0.07)",
        marginTop: '46px',
        marginBottom: '5%'
    },
    "& .reviewTextTitle": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "26px",
        color: "#1C2110",
    },
    "& .downloadBtn": {
        backgroundColor: '#8CA74B',
        textTransform: 'none',
        borderRadius: '8px',
        padding: '5px',
        height: '44px',
        width: '170px'
    },
    "& .btnTitle": {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#FFFFFF",
        marginLeft: '8px'
    }
});
// Customizable Area End