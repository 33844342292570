import React from "react";
// Customizable Area Start
import { Box, Button, Typography, Container, IconButton, styled, Card, CardContent } from "@material-ui/core";
import Header from "../../../components/src/Header.web";
// Customizable Area End
import MomentsPageController, {
    Props,
} from "./MomentsPageController";
import { shareBeach, uploadFilesImage, photosBanner, outlineCheck, recipeDesign1, recipeDesign2, recipeDesign3, recipeDesign4, recipeDesign5, recipeFeatureBg, downArrow, recipeDesign8, recipeDesign10, photosImage, familyTreePin } from "./assets";
import Footer from "../../../components/src/Footer.web";

export default class PhotosPage extends MomentsPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderFAQSection() {
        const { landingData } = this.state;

        return (
            <Container maxWidth="md" style={{ marginBottom: 100, marginTop: 60 }}>
                <div style={{ width: '100%', maxWidth: 920, marginTop: 50, margin: 'auto' }}>
                    {landingData.length > 0 && landingData[0]?.landing_page_faqs?.length > 0 ? (
                        landingData[0].landing_page_faqs.map((faq: any, index: any) => (
                            <Box
                                key={faq.id}
                                style={{
                                    borderRadius: '4px',
                                    marginBottom: '16px',
                                }}
                            >
                                <Box
                                    style={{
                                        display: 'flex',
                                        padding: '12px',
                                        cursor: 'pointer',
                                        alignItems: 'center',
                                        borderBottom: this.state.recipeFaq === index ? 'none' : '1px solid #D6D3D1',
                                    }}
                                    onClick={() => this.handleAccordian(index)}
                                    data-test-id={`coveracordian-${index + 1}`}
                                >
                                    <Typography style={{fontFamily: 'Poppins',  flex: 1, fontSize: 18, fontWeight: 700 }}>
                                        {faq.question}
                                    </Typography>
                                    <IconButton
                                        aria-expanded={this.state.recipeFaq === index}
                                        style={{ marginLeft: 'auto', transition: 'transform 0.3s ease' }}
                                        size="small"
                                        aria-label="show more"
                                    >
                                        <img
                                            src={downArrow}
                                            style={{
                                                transform: this.state.recipeFaq === index ? 'rotate(180deg)' : 'rotate(0deg)',
                                                width: 24,
                                            }}
                                        />
                                    </IconButton>
                                </Box>
                                {this.state.recipeFaq === index && (
                                    <Box style={{padding: '12px', borderBottom: '1px solid #D6D3D1'  }}>
                                        <Typography
                                            style={{
                                                fontWeight: 400,
                                                fontFamily: 'Poppins',
                                                flex: 1,
                                                fontSize: 16,
                                                color: '#5D6063',
                                            }}
                                        >
                                            {faq.answer}
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        ))
                    ) : (
                        <Typography>No FAQs available</Typography>
                    )}
                </div>
            </Container>
        );
    }

    renderFeatureSection() {
        const { landingData } = this.state;

        return (
            <Box style={{ display: 'flex', justifyContent: 'center', marginTop: 100, position: "relative" }}>
                <Box style={webStyles.backgroundImageFeature as any}>
                    <img src={recipeDesign8} style={{ width: "100%", maxWidth: "229px", position: 'absolute', right: 0, top: -80, height: 242 }} />
                    <img src={recipeDesign2} style={{ width: "100%", maxWidth: "115px", position: 'absolute', left: 0, top: 80, height: 122 }} />
                    <img src={recipeDesign10} style={{ width: "100%", maxWidth: "180px", position: 'absolute', right: 0, top: 700, height: 352, zIndex: "-1" }} />
                    <img src={familyTreePin} style={{ width: "100%", maxWidth: "390px", position: 'absolute', left: 0, top: 1800, zIndex: "-1" }} />
                    <Typography style={webStyles.bestFeatures}>How Our Family Photo Storage Works</Typography>
                    <Typography style={webStyles.bestFeatures2 as any}>MyKinship offers a seamless and secure way to manage your family’s digital photo collection. Here’s how it works</Typography>
                    <Box style={{ maxWidth: "1300px", width: "100%" }}>
                        {landingData.length > 0 && landingData[0]?.work_storages?.length > 0 ? (
                            landingData[0].work_storages.map((storage: any, index: any) => (
                                <Box
                                    key={index}
                                    style={{
                                        display: "flex",
                                        marginTop: "100px",
                                        flexDirection: index % 2 === 0 ? "row" : "row-reverse",
                                    }}
                                >
                                    <img
                                        src={storage.content_image_url}
                                        style={{ width: "604px" }}
                                    />
                                    <Box
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            marginLeft: index % 2 === 0 ? 50 : 0,
                                            marginRight: index % 2 !== 0 ? 50 : 0,
                                            marginTop: 130,
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                fontSize: 36,
                                                fontWeight: 700,
                                                fontFamily: "Poppins",
                                            }}
                                        >
                                            {storage.title}
                                        </Typography>
                                        <Typography
                                            style={{
                                                fontSize: 24,
                                                fontWeight: 400,
                                                fontFamily: "Poppins",
                                                marginTop: 10,
                                            }}
                                        >
                                            {storage.description}
                                        </Typography>
                                    </Box>
                                </Box>
                            ))
                        ) : (
                            <Typography>No work storages available.</Typography>
                        )}
                    </Box>
                </Box>
            </Box>
        );
    }


    // Customizable Area End

    render() {
        // Customizable Area Start
        const { landingData } = this.state;
        const backgroundStyle = {
            backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0.5) 30%, rgba(255, 255, 255, 1) 70%), url(${landingData[0]?.background_image_url})`,
            backgroundSize: 'cover',
            width: '100%',
            maxWidth: '1635px',
            backgroundPosition: 'center',
            height: '850px',
            display: 'flex',
            justifyContent: 'end',
        };

        return (
            //Merge Engine DefaultContainer
            <Box>
                <Header data-test-id="header" onLoginClick={this.onLoginClick} onRegisterClick={this.onRegisterClick}
                    getHeaderData={(data) => {
                        this.landingPageFilterAPi(data);
                    }}
                />
                <Box style={backgroundStyle}>
                    <Box style={{ width: "100%", maxWidth: "666px" }}>
                        <Typography style={webStyles.HeaderText}>{landingData[0]?.title}</Typography>
                        <Typography style={webStyles.subHeaderPhotos}>
                            {landingData[0]?.description}
                        </Typography>
                        <Button type="submit" style={webStyles.continueButtonPhotos}>
                            <Typography style={webStyles.continueText as any}>Get started</Typography>
                        </Button>
                    </Box>
                </Box>
                <Box>
                    <Box style={{ display: "flex", gap: 80, justifyContent: 'center', marginTop: 60, position: 'relative' }}>

                        <img src={recipeDesign5} style={{ width: "100%", maxWidth: "297px", position: 'absolute', right: 194, top: -120, zIndex: "-1" }} />
                        <img src={recipeDesign1} style={{ width: "100%", maxWidth: "297px", position: 'absolute', left: 0, top: -100, zIndex: -1 }} />
                        <img src={recipeDesign3} style={{ width: "100%", maxWidth: "342px", position: 'absolute', right: 0, top: -470, height: "356px" }} />

                        <Card style={{ width: "100%", maxWidth: "1133px", color: "#fff", borderRadius: 16, backgroundColor: "#fff", padding: 30, boxShadow: "0 4px 8px rgba(0.5, 0.5, 0.5, 0.5)" }}>
                            <CardContent>
                                <Typography style={{ fontWeight: 700, fontFamily: "Poppins", fontSize: 48, color: "#305328", textAlign: "center" }}>
                                    {landingData[0]?.content_title}
                                </Typography>
                                <Typography style={{ fontWeight: 400, fontFamily: "Poppins", fontSize: 20, color: "1C2110" }}>
                                    {landingData[0]?.content_description}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Box>
                </Box>
                <PhotoPageStyle style={{ display: 'flex', justifyContent: 'space-around', marginTop: 60 }}>
                    {landingData?.[0]?.benefits?.map((benefit: any, index: any) => (
                        <Box className="priceBox2" key={benefit.id}>
                            <Typography className="priceTitle2">{benefit.title}</Typography>
                            <Box className="priceCenterDiv2">
                                <Typography className="priceSubTitle2" dangerouslySetInnerHTML={{ __html: benefit.description }} />
                            </Box>
                            <Button className="startBtn2" onClick={this.startNowBtn}>Start now</Button>
                        </Box>
                    )) || null}
                </PhotoPageStyle>
                {this.renderFeatureSection()}
                <Box style={webStyles.frequentlyBox}>
                    <Typography style={webStyles.frequentlyText2}>Frequently asked questions</Typography>
                    <Typography style={webStyles.simpleText}>Here are some of the most frequently asked questions about MyKinship’s Family Photo Storage Solutions.</Typography>
                </Box>
                {this.renderFAQSection()}

                <Box style={{ position: 'relative' }}>
                    <Box style={{ maxWidth: 1240, width: '100%', margin: 'auto' }}>
                        <img src={recipeDesign4} style={{ objectFit: 'contain', position: 'absolute', right: 0, height: 420, top: -80, zIndex: -1 }} />
                        <Typography style={webStyles.moreQuestions}>Do You Have More Questions about Our Family Photo Storage Online?</Typography>
                        <Typography style={webStyles.moreQuestions2}>MyKinship is an online digital storage service that specializes in organizing and preserving your family photos. If you have any additional questions regarding our service, don’t hesitate to contact us. </Typography>
                    </Box>
                </Box>
                <Footer />

            </Box>

            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const webStyles = {
    container: {
        display: "grid",
        gridTemplateColumns: "3fr 7fr",
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        padding: "0.5rem 1rem",
        minHeight: "12rem",
        gap: "2rem",
        width: "32rem",
    },
    HeaderText: {
        fontSize: 72,
        fontWeight: 400,
        color: '#305328',
        fontFamily: 'Abygaer',
        marginTop: 130,
    },
    subHeaderPhotos: {
        color: '#305328',
        fontWeight: 400,
        fontFamily: "Poppins",
        fontSize: 24,
    },
    continueButtonPhotos: {
        backgroundColor: "#8CA74B",
        width: '292px',
        borderRadius: '8px',
        height: '56px',
        marginTop: '32px',
    },
    continueText: {
        fontFamily: "Poppins",
        lineHeight: "24px",
        fontWeight: 700,
        fontSize: "16px",
        color: "#FFFFFF",
        textTransform: 'none'
    },
    familyText: {
        color: "#305328",
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: 48
    },
    familyText2: {
        color: "#305328",
        fontWeight: 700,
        fontFamily: "Poppins",
        fontSize: 48,
        textAlign: 'right',
    },
    contentText: {
        fontWeight: 400,
        fontFamily: "Poppins",
        fontSize: 20,
        color: "#1C2110"
    },
    textContainer: {
        maxWidth: "656px",
        width: "100%",
    },
    backgroundImageFeature: {
        backgroundImage: `url(${recipeFeatureBg})`,
        backgroundPosition: 'center',
        width: '100%',
        height: '2070px',
        display: 'flex',
        backgroundSize: 'cover',
        flexDirection: "column",
        alignItems: 'center',
    },
    bestFeatures: {
        color: "#305328",
        fontWeight: 700,
        fontSize: 48,
        fontFamily: "Poppins",
        marginTop: 30
    },
    backgroundImageBanner: {
        backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0.5) 30%, rgba(255, 255, 255, 1) 70%), url(${photosBanner})`,
        backgroundSize: 'cover',
        width: '100%',
        maxWidth: "1635px",
        backgroundPosition: 'center',
        height: '850px',
        display: 'flex',
        justifyContent: "end",
    },
    frequentlyBox: { maxWidth: 1240, margin: 'auto', width: '100%' },
    bestFeatures2: {
        color: "#5D6063",
        width: "100%",
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: 20,
        maxWidth: "1200px"
    },
    frequentlyText2: {
        fontSize: 36,
        fontFamily: 'Poppins',
        fontWeight: 700,
        color: '#1C1917',
        marginTop: 100
    },
    simpleText: {
        fontFamily: 'Poppins',
        fontSize: 18,
        fontWeight: 400,
        color: '#5D6063'
    },
    moreQuestions: {
        fontFamily: "Poppins",
        fontSize: 36,
        fontWeight: 700,
        color: "#1C1917"
    },
    moreQuestions2: {
        fontWeight: 400,
        fontSize: 18,
        color: "#5D6063",
        fontFamily: "Poppins",
        marginTop: 10,
        marginBottom: 195
    },
};

const PhotoPageStyle = styled("div")({


    "& .priceBox2": {
        width: '392',
        height: '398',
        boxShadow: "0 0 5px rgba(0, 0, 0, 0.5)",
        borderRadius: '16px',
    },
    "& .priceTitle2": {
        lineHeight: "44px",
        fontWeight: 700,
        fontSize: "36px",
        letterSpacing: '-1%',
        fontFamily: 'Poppins',
        color: '#1C2110',
        marginBottom: '5px',
        marginTop: '40px',
        textAlign: 'center'
    },
    "& .priceSubTitle2": {
        lineHeight: "26px",
        fontWeight: 400,
        fontSize: "18px",
        fontFamily: 'Poppins',
        color: '#1C2110',
        textAlign: 'center'
    },
    "& .priceCenterDiv2": {
        height: '140px',
        backgroundColor: "#E8EDDB",
        textAlign: 'center',
        display: "flex",
        alignItems: "center",
        marginTop: 40
    },

    "& .priceCenterBox2": {
        backgroundColor: "#557C29",
        height: '140px'
    },
    "& .priceTag2": {
        lineHeight: "56px",
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: "64px",
        color: '#FFFFFF',
        textAlign: 'center',
        letterSpacing: '-1.5%',
        padding: '13px',
    },
    "& .dollar2": {
        lineHeight: "56px",
        fontWeight: 300,
        fontSize: "32px",
        fontFamily: 'Inter',
        color: '#FFFFFF',
        letterSpacing: '-1.5%',
    },
    "& .monthText2": {
        lineHeight: "28px",
        fontWeight: 400,
        fontSize: "20px",
        color: '#FFFFFF',
        marginBottom: '20px',
        textAlign: 'center',
        fontFamily: 'Poppins',
        display: "block",
        alignItems: "center",
    },
    "& .storageText2": {
        lineHeight: "28px",
        fontWeight: 400,
        fontSize: "20px",
        fontFamily: 'Poppins',
        color: '#1C2110',
        marginBottom: '40px',
        textAlign: 'center',
        marginTop: '20px',
    },
    "& .startBtn2": {
        backgroundColor: '#8CA74B',
        borderRadius: '8px',
        marginTop: '40px',
        marginBottom: '48px',
        padding: '10px',
        marginLeft: "auto",
        marginRight: "auto",
        textTransform: "none",
        display: "block",
        width: '182px',
        "& .Muibutton-label": {
            lineHeight: "24px",
            fontWeight: 700,
            fontFamily: 'Poppins',
            color: '#FFFFFF',
            fontSize: "16px",
            textAlign: 'center',
        }
    }
})




// Customizable Area End
