import React, { Component } from 'react';
import { Button, styled, Box, IconButton, MenuItem, Menu, Divider, withStyles, MenuProps, ListItemIcon, ListItemText } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { avatar, logo, subscription, logout } from '../../blocks/subscriptionbilling/src/assets';
import { Typography } from 'antd';
import PaymentIcon from '@material-ui/icons/Payment';

interface HeaderProps {
    editProfileClick: () => void;
    subscriptionClick: () => void;
    paymentClick: () => void;
}

interface HeaderState {
    anchorEl: null | HTMLElement;
}

const StyledMenu = withStyles({
    paper: {
        width: '280px',
        borderRadius: '8px',
        border: '1px solid #D6D3D1',
    },
})((props: MenuProps) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        color: '#1C2110',
        fontFamily: 'Poppins',
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: '24px',
    },
}))(MenuItem);

class HeaderDropdown extends Component<HeaderProps, HeaderState> {
    constructor(props: HeaderProps) {
        super(props);
        this.state = {
            anchorEl: null,
        };
    }

    handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    render() {
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        const { editProfileClick } = this.props;
        const { subscriptionClick } = this.props;
        const { paymentClick } = this.props;
        return (
            <HeaderStyle>
                <Box className="headerDiv">
                    <Box className="logoSection">
                        <img style={{ height: '44px', width: '32px' }} src={logo} />
                    </Box>
                    <Box className="profileDiv">
                        <img src={avatar} />
                        <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={this.handleClick}
                        >
                            <ExpandMoreIcon fontSize="inherit" />
                        </IconButton>
                    </Box>

                    <StyledMenu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={this.handleClose}
                    >
                        <Box style={{ display: 'flex', padding: '10px' }}>
                            <Box>
                                <img style={{ width: '56px', height: '56px' }} src={avatar} />
                            </Box>
                            <Box style={{ marginLeft: '13px' }}>
                                <Typography style={{
                                    fontFamily: 'Poppins',
                                    fontWeight: 400,
                                    fontSize: "16px",
                                    lineHeight: '24px',
                                }}>
                                    {localStorage.getItem("fullName")}
                                </Typography>
                                <Typography style={{
                                    marginTop: '3px',
                                    fontFamily: 'Poppins',
                                    fontWeight: 400,
                                    fontSize: "16px",
                                    lineHeight: '24px',
                                }}>
                                    {localStorage.getItem("email")}
                                </Typography>
                                <Button style={{
                                    backgroundColor: '#8CA74B',
                                    borderRadius: '8px',
                                    marginTop: '10px',
                                    fontFamily: "Poppins",
                                    textTransform: "none",
                                    fontSize: "14px",
                                    fontWeight: 700,
                                    color: '#FFFFFF',
                                    lineHeight: "22px",
                                    marginBottom: '5px'

                                }}
                                    onClick={editProfileClick}
                                >Edit Profile</Button>
                            </Box>
                        </Box>
                        <Divider />
                        <StyledMenuItem >
                            <Button style={{
                                color: '#78716C',
                                fontFamily: 'Poppins',
                                fontWeight: 400,
                                fontSize: "16px",
                                lineHeight: '24px',
                                textTransform: 'none'
                            }} >
                                SETTINGS
                            </Button>
                        </StyledMenuItem>
                        <StyledMenuItem >
                            <img src={subscription} />
                            <Button style={{
                                color: '#1C2110',
                                fontFamily: 'Poppins',
                                fontWeight: 400,
                                fontSize: "16px",
                                lineHeight: '24px',
                                textTransform: 'none'
                            }} onClick={subscriptionClick}>
                                My subscription
                            </Button>
                        </StyledMenuItem>
                        <StyledMenuItem>
                            <PaymentIcon />
                            <Button style={{
                                color: '#1C2110',
                                fontFamily: 'Poppins',
                                fontWeight: 400,
                                fontSize: "16px",
                                lineHeight: '24px',
                                textTransform: "none"
                            }} onClick={paymentClick}>
                                Payments
                            </Button>
                        </StyledMenuItem>
                        <Divider />
                        <StyledMenuItem>
                            <img src={logout} />
                            <Button style={{
                                color: '#1C2110',
                                fontFamily: 'Poppins',
                                fontWeight: 400,
                                fontSize: "16px",
                                lineHeight: '24px',
                                textTransform: "none"
                            }} >Log out
                            </Button>
                        </StyledMenuItem>
                    </StyledMenu>
                </Box>
            </HeaderStyle>
        );
    }
}

const HeaderStyle = styled("div")({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    borderBottom: '1px solid #E7E5E4',
    flexGrow: 1,
    boxShadow: "0 0 10px 5px rgba(0, 0, 0, 0.07)",
    "& .headerDiv": {
        display: 'flex',
        justifyContent: 'space-between', 
        alignItems: 'center',
        width: '100%',
        padding: '20px',
        borderBottom: '1px solid #E7E5E4'
    },
    "& .profileDiv": {
        display: 'flex',
        alignItems: 'center',
    },
    "& .logoSection": {
        display: 'flex',
        alignItems: 'center'
    }
});

export default HeaderDropdown;