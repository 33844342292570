import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
  // Customizable Area Start
  interface Plan {
    id: number;
    name: string;
    details: string;
    storage: number;
    price: string;
    storage_unit: string;
    stripe_product_id: string;
    stripe_price_id: string;
    created_at: string;
    updated_at: string;
  }
  
  // Customizable Area End

interface S {
  // Customizable Area Start
  recipeFaq: boolean;
  subscriptionData: Plan[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> { 
  // Customizable Area Start
  getAllSubscriptionApiCallId:any;
  getAllFaqsApiCallID:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      recipeFaq: false,
      subscriptionData: []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
       
        if(apiRequestCallId === this.getAllSubscriptionApiCallId){
          this.setState({subscriptionData: responseJson})
        }
        else if(apiRequestCallId === this.getAllFaqsApiCallID){
          console.log("response 95",responseJson);
          
        }
      }
      }
    // Customizable Area End
  }

  // Customizable Area Start
  
  async componentDidMount(): Promise<void> {
    this.getAllSubscription();
    this.getAllFaqsApi();
    }
    
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  onLoginClick = () => {
    this.props.navigation.navigate("Login")
  }

  onRegisterClick = () => {
    this.props.navigation.navigate("Signuplogin")
  }

  communityBtn = () => {
    this.props.navigation.navigate("Signuplogin")
  }

  startNowBtn = () => {
    this.props.navigation.navigate("Signuplogin")
  }

  handleAccordian = () => {
    this.setState((prevState) => ({ recipeFaq: !prevState.recipeFaq }));
  };

 

  getAllSubscription = () => {
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllSubscriptionApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllSubscriptionEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  getAllFaqsApi = () => {
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllFaqsApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllFaqsApiEndPoint + "?id=" + 5
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End
}
