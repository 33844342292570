import React from "react";
// Customizable Area Start
import { Box, Button, Typography, Container, IconButton, styled } from "@material-ui/core";
import Header from "../../../components/src/Header.web";
// Customizable Area End
import MomentsPageController, {
  Props,
} from "./MomentsPageController";
import { familyTreeBanner, recipeDesign1, recipeBannerEmpty, recipeDesign2, recipeDesign3, recipeDesign4, recipeDesign5, recipeFeatureBg, share, uploadImage, downArrow, recipeDesign6, recipeDesign8, recipeDesign9, recipeDesign10, outlineCheck, organized, cloudDone } from "./assets";
import Footer from "../../../components/src/Footer.web";

export default class FamilyTreePage extends MomentsPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderFAQSection() {
    const { landingData } = this.state;

    return (
        <Container maxWidth="md" style={{ marginBottom: 100, marginTop: 60 }}>
            <div style={{ marginTop: 50, width: '100%', maxWidth: 920, margin: 'auto' }}>
                {landingData.length > 0 && landingData[0]?.landing_page_faqs?.length > 0 ? (
                    landingData[0].landing_page_faqs.map((faq: any, index: any) => (
                        <Box
                            key={faq.id}
                            style={{
                                marginBottom: '16px',
                                borderRadius: '4px',
                            }}
                        >
                            <Box
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    padding: '12px',
                                    borderBottom: this.state.recipeFaq === index ? 'none' : '1px solid #D6D3D1',
                                }}
                                onClick={() => this.handleAccordian(index)}
                                data-test-id={`coveracordian-${index + 1}`}
                            >
                                <Typography style={{fontFamily: 'Poppins',  flex: 1, fontSize: 18, fontWeight: 700 }}>
                                    {faq.question}
                                </Typography>
                                <IconButton
                                    aria-expanded={this.state.recipeFaq === index}
                                    size="small"
                                    aria-label="show more"
                                    style={{ marginLeft: 'auto', transition: 'transform 0.3s ease' }}
                                >
                                    <img
                                        src={downArrow}
                                        style={{
                                            transform: this.state.recipeFaq === index ? 'rotate(180deg)' : 'rotate(0deg)',
                                            width: 24,
                                        }}
                                    />
                                </IconButton>
                            </Box>
                            {this.state.recipeFaq === index && (
                                <Box style={{borderBottom: '1px solid #D6D3D1' , padding: '12px' }}>
                                    <Typography
                                        style={{
                                            fontFamily: 'Poppins',
                                            flex: 1,
                                            fontSize: 16,
                                            fontWeight: 400,
                                            color: '#5D6063',
                                        }}
                                    >
                                        {faq.answer}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    ))
                ) : (
                    <Typography>No FAQs available</Typography>
                )}
            </div>
        </Container>
    );
}
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { landingData } = this.state
    console.log("response 25", landingData[0]?.background_image_url);

    const backgroundStyle = {
      backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0.5) 30%, rgba(255, 255, 255, 1) 70%), url(${landingData[0]?.background_image_url})`,
      backgroundSize: 'cover',
      width: '100%',
      maxWidth: '1635px',
      backgroundPosition: 'center',
      height: '850px',
      display: 'flex',
      justifyContent: 'end',
    };
    return (
      //Merge Engine DefaultContainer
      <Box>
        <Header data-test-id="header" onLoginClick={this.onLoginClick} onRegisterClick={this.onRegisterClick}
          getHeaderData={(data) => {
            this.landingPageFilterAPi(data);
          }}
        />
        <Box style={backgroundStyle}>
          <Box style={{ maxWidth: "666px", width: "100%" }}>
            <Typography style={webStyles.HeaderTextTree}>{landingData[0]?.title}</Typography>
            <Typography style={webStyles.subHeaderTree}>
              {landingData[0]?.description}
            </Typography>
            <Button type="submit" style={webStyles.continueButtonTree}>
              <Typography style={webStyles.continueTextTree as any}>Get started</Typography>
            </Button>
          </Box>
        </Box>

        <Box>
          <Box style={webStyles.workStorageBox as any}>
            <Box style={{ width: "100%", maxWidth: "656px" }}>
              <img src={recipeDesign5} style={{ width: "100%", maxWidth: "297px", right: 194, position: 'absolute', top: -120 }} />
              <img src={recipeDesign1} style={{ width: "100%", maxWidth: "297px", position: 'absolute', left: 0, top: -100, zIndex: -1 }} />
              <img src={recipeDesign3} style={{ width: "100%", maxWidth: "342px", position: 'absolute', top: -470, right: 0, height: "356px" }} />

              {landingData[0]?.work_storages?.length > 0 ? (
                <>
                  <Typography style={webStyles.familyTextTree as React.CSSProperties}>
                    {landingData[0]?.work_storages[0]?.title}
                  </Typography>
                  <Typography style={webStyles.familyTreeDescription}>
                    {landingData[0]?.work_storages[0]?.description}
                  </Typography>
                </>
              ) : (
                <Typography>No work storage data available</Typography>
              )}

            </Box>
            <img src={recipeBannerEmpty} style={{ maxWidth: "605px", width: "100%", zIndex: 9999 }} />
          </Box>
        </Box>
        <Box style={{ position: "relative" }}>
          <Box style={{ display: "flex", justifyContent: 'center', marginTop: 150, gap: 80 }}>
            <img src={recipeDesign2} style={{ maxWidth: "230px", width: "100%", position: 'absolute', left: 0, top: -280, height: 320 }} />
            <img src={recipeDesign6} style={{ maxWidth: "230px", width: "100%", position: 'absolute', right: 0, height: 270, top: 260 }} />
            <img src={recipeBannerEmpty} style={{ maxWidth: "605px", width: "100%", height: 450 }} alt="Recipe Banner" />

            <Box style={webStyles.textContainer}>
              {landingData[0]?.work_storages?.[1] ? (
                <>
                  <Box>
                    <Typography style={webStyles.familyText2 as React.CSSProperties}>
                      {landingData[0].work_storages[1].title}
                    </Typography>
                  </Box>
                  <Typography style={webStyles.contentText as React.CSSProperties}>
                    {landingData[0].work_storages[1].description}
                  </Typography>
                </>
              ) : (
                <Typography>No work storage data available</Typography>
              )}
            </Box>

          </Box>
        </Box>

        <FamiltTreeStyle style={{ display: 'flex', marginTop: 60, justifyContent: 'space-around', }}>
          {landingData?.[0]?.benefits?.map((benefit: any, index: any) => (
            <Box className="priceBox3" key={benefit.id}>
              <Typography className="priceTitle3">{benefit.title}</Typography>
              <Box className="priceCenterDiv3">
                <Typography className="priceSubTitle3" dangerouslySetInnerHTML={{ __html: benefit.description }} />
              </Box>
              <Button className="startBtn3" onClick={this.startNowBtn}>Start now</Button>
            </Box>
          )) || null}
        </FamiltTreeStyle>
        <Box style={{ display: 'flex', marginTop: 100, justifyContent: 'center', position: "relative" }}>
          <Box style={webStyles.backgroundImageFeature as any}>
            <img src={recipeDesign8} style={{ width: "100%", position: 'absolute', maxWidth: "229px", right: 0, top: -80, height: 242 }} />
            <img src={recipeDesign2} style={{ width: "100%", position: 'absolute', left: 0, top: 80, maxWidth: "115px", height: 122 }} />
            <img src={recipeDesign10} style={{ width: "100%", position: 'absolute', right: 0, maxWidth: "180px", top: 300, height: 352 }} />

            <Typography style={webStyles.bestFeaturesTree}>The Best Features For Your Recipes</Typography>
            <Typography style={webStyles.bestFeatures2Tree as any}>MyKinship works as a recipe organizer and meal planner and a recipe management
              turning handwritten recipes into digital recipes for easier storage.</Typography>
            <Box style={{ width: "100%", maxWidth: "950px", marginTop: 40 }}>
              {landingData[0]?.landing_page_features?.length ? (
                landingData[0].landing_page_features.map((feature: any, index: any) => (
                  <Box
                    key={index}
                    style={{
                      backgroundColor: '#FFFFFF',
                      marginBottom: 40,
                      height: 180,
                      display: 'flex',
                      position: 'relative',
                      borderRadius: 8,
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
                    }}
                  >
                    <img
                      data-test-id="imageElement"
                      src={this.getImageSource(index)}
                      alt={`Feature ${index}`}
                      style={{ width: 60, height: 60, margin: "auto", padding: 20 }}
                    />
                    <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: 20 }}>
                      <Typography style={webStyles.featureTitle}>
                        {feature.title}
                      </Typography>
                      <Typography style={webStyles.featureDescription}>
                        {feature.description}
                      </Typography>
                    </Box>
                    <img
                      src={recipeDesign9}
                      style={{
                        width: "100%",
                        position: 'absolute',
                        height: 142,
                        maxWidth: "152px",
                        top: 130,
                        zIndex: "-1",
                        [index % 2 === 0 ? 'right' : 'left']: 0,
                      }}
                      alt="Design 9"
                    />
                  </Box>
                ))
              ) : (
                <Typography>No features available</Typography>
              )}
            </Box>
          </Box>

        </Box>
        <Box style={{ margin: 'auto', maxWidth: 1240, width: '100%' }}>
          <Typography style={webStyles.frequentlyText}>Frequently asked questions</Typography>
          <Typography style={webStyles.simpleText}>Simple, transparent pricing that grows with you. Try any plan free for 30 days.</Typography>
        </Box>
        {this.renderFAQSection()}
        <Box style={{ position: 'relative' }}>
          <Box style={{ maxWidth: 1240, width: '100%', margin: 'auto' }}>
            <img src={recipeDesign4} style={{ objectFit: 'contain', position: 'absolute', height: 420, top: -80, zIndex: -1, right: 0 }} />
            <Typography style={webStyles.moreQuestions}>Do You Have More Questions about Building Your Family Tree?</Typography>
            <Typography style={webStyles.moreQuestions2}>Sign up today to begin developing your family tree. If you have any additional questions about our service, please don’t hesitate to contact MyKinship.</Typography>
          </Box>
        </Box>
        <Footer />
      </Box>

      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  container: {
    display: "grid",
    padding: "0.5rem 1rem",
    gridTemplateColumns: "3fr 7fr",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    width: "32rem",
    minHeight: "12rem",
    gap: "2rem",
  },
  HeaderTextTree: {
    fontSize: 72,
    fontWeight: 400,
    color: '#305328',
    fontFamily: 'Abygaer',
    marginTop: 130,
  },
  subHeaderTree: {
    color: '#305328',
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: 24,
  },
  backgroundImage: {
    backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0.5) 30%, rgba(255, 255, 255, 1) 70%), url(${familyTreeBanner})`,
    backgroundSize: 'cover',
    width: '100%',
    maxWidth: "1635px",
    height: '614px',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: "end",
  },
  continueButtonTree: {
    backgroundColor: "#8CA74B",
    borderRadius: '8px',
    height: '56px',
    width: '292px',
    marginTop: '32px',
  },
  continueTextTree: {
    fontFamily: "Poppins",
    color: "#FFFFFF",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    textTransform: 'none'
  },
  familyTextTree: {
    color: "#305328",
    fontWeight: 700,
    fontFamily: "Poppins",
    fontSize: 48
  },
  textContainer: {
    width: "100%",
    maxWidth: "656px"
  },
  familyText2: {
    color: "#305328",
    fontWeight: 700,
    fontSize: 48,
    fontFamily: "Poppins",
    textAlign: 'right',
    width: "100%",
    maxWidth: "720px"
  },
  contentText: {
    fontWeight: 400,
    fontSize: 20,
    fontFamily: "Poppins",
    color: "#1C2110"
  },
  bestFeaturesTree: {
    color: "#305328",
    fontWeight: 700,
    fontSize: 48,
    fontFamily: "Poppins",
    marginTop: 30
  },

  bestFeatures2Tree: {
    color: "#5D6063",
    fontFamily: "Poppins",
    width: "100%",
    fontWeight: 400,
    fontSize: 20,
    textAlign: 'center',
    maxWidth: "900px"
  },
  backgroundImageFeature: {
    backgroundImage: `url(${recipeFeatureBg})`,
    backgroundSize: 'cover',
    width: '100%',
    height: '1100px',
    display: 'flex',
    backgroundPosition: 'center',
    flexDirection: "column",
    alignItems: 'center',
  },
  frequentlyText: {
    fontSize: 36,
    fontFamily: 'Poppins',
    color: '#1C1917',
    fontWeight: 700,
    marginTop: 100
  },
  simpleText: {
    fontSize: 18,
    fontWeight: 400,
    fontFamily: 'Poppins',
    color: '#5D6063'
  },

  moreQuestions: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: 36,
    color: "#1C1917"
  },
  moreQuestions2: {
    fontWeight: 400,
    color: "#5D6063",
    fontFamily: "Poppins",
    fontSize: 18,
    marginTop: 10,
    marginBottom: 195
  },
  workStorageBox:
  {
    display: "flex",
    gap: 80,
    justifyContent: 'center',
    marginTop: 60,
    position: 'relative'
  },
  familyTreeDescription: {
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: 20,
    color: "#1C2110"
  },
  featureTitle:
  {
    fontWeight: 700, fontSize: 48, fontFamily: 'Poppins', color: "#305328"
  },
  featureDescription:
    { fontSize: 20, fontWeight: 400, fontFamily: "Poppins" }
};

const FamiltTreeStyle = styled("div")({

  "& .priceMainBox3": {
    justifyContent: 'space-between',
    display: 'flex',
    width: '94%',
  },
  "& .priceBox3": {
    borderRadius: '16px',
    width: '392',
    height: '398',
    boxShadow: "0 0 5px rgba(0, 0, 0, 0.5)",
  },
  "& .priceTitle3": {
    lineHeight: "44px",
    fontWeight: 700,
    marginBottom: '5px',
    fontSize: "36px",
    fontFamily: 'Poppins',
    color: '#1C2110',
    letterSpacing: '-1%',
    marginTop: '40px',
    textAlign: 'center'
  },
  "& .priceSubTitle3": {
    lineHeight: "26px",
    fontSize: "18px",
    fontFamily: 'Poppins',
    color: '#1C2110',
    fontWeight: 400,
    marginBottom: '20px',
    textAlign: 'center'
  },
  "& .priceCenterDiv3": {
    backgroundColor: "#E8EDDB",
    textAlign: 'center',
    display: "flex",
    alignItems: "center",
    marginTop: 40
  },

  "& .priceCenterBox": {
    height: '140px',
    backgroundColor: "#557C29",
  },
  "& .priceTag3": {
    lineHeight: "56px",
    fontWeight: 500,
    fontFamily: 'Inter',
    color: '#FFFFFF',
    fontSize: "64px",
    textAlign: 'center',
    letterSpacing: '-1.5%',
    padding: '13px',
  },
  "& .dollar3": {
    lineHeight: "56px",
    fontSize: "32px",
    fontFamily: 'Inter',
    fontWeight: 300,
    color: '#FFFFFF',
    letterSpacing: '-1.5%',
  },
  "& .monthText3": {
    lineHeight: "28px",
    fontWeight: 400,
    fontSize: "20px",
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    marginBottom: '20px',
    textAlign: 'center',
    display: "block",
    alignItems: "center",
  },
  "& .storageText3": {
    lineHeight: "28px",
    fontSize: "20px",
    fontFamily: 'Poppins',
    fontWeight: 400,
    color: '#1C2110',
    marginBottom: '40px',
    textAlign: 'center',
    marginTop: '20px',
  },
  "& .startBtn3": {
    backgroundColor: '#8CA74B',
    borderRadius: '8px',
    marginTop: '40px',
    marginBottom: '48px',
    padding: '10px',
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    width: '182px',
    "& .Muibutton-label": {
      lineHeight: "24px",
      textAlign: 'center',
      fontWeight: 700,
      fontSize: "16px",
      fontFamily: 'Poppins',
      color: '#FFFFFF',
    }
  }
})


// Customizable Area End
