Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "subscriptionbilling";
exports.labelBodyText = "subscriptionbilling Body";

exports.btnExampleTitle = "CLICK ME";
exports.createSubscriptionEndPoint = "bx_block_subscriptionbilling/create_subscription";
exports.createSubscriptionAchEndPoint = "bx_block_subscriptionbilling/bank_account_payment_subscription"
exports.getSubscriptionEndPoint = "bx_block_subscriptionbilling/get_payment_success"
exports.getBankSubscriptionEndPoint = "bx_block_subscriptionbilling/payment_details"
exports.createSubscriptionApiMethodType = "POST";
exports.createSubscriptionAchApiMethodType = "POST"
exports.deleteApiType = "DELETE"
exports.getDeleteCreditCardEndPoint = "bx_block_subscriptionbilling/delete_card";
exports.getDeleteBankEndPoint = "bx_block_subscriptionbilling/delete_bank";
exports.getCardDetails = "bx_block_subscriptionbilling/get_card";
exports.getBankDetails = "bx_block_subscriptionbilling/get_bank";
exports.getBillingAddressEndPoint = "bx_block_subscriptionbilling/billing_addresses";
exports.getBillingAddressApiMethodType = "POST"
exports.getAllSubscriptionEndPoint = "bx_block_subscriptionbilling/subscription_plan";
exports.getAllPlansEndPoint = "bx_block_subscriptionbilling/subscription_plan/find_by_name";
exports.getSubscriptionBillingFaqEndPoint = "account_block/question_and_answer";
exports.deleteCardAchEndPoint = "bx_block_subscriptionbilling/detach_payment_method";
exports.deleteBankOrCardEndPoint = "bx_block_subscriptionbilling/get_payment_methods"
// Customizable Area End