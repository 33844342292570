export const bgImageTop = require("../assets/bgImage.png");
export const imgBottom1 = require("../assets/imgbottom1.png");
export const imgBottom2 = require("../assets/imgBottom2.png");
export const imgBottom3 = require("../assets/imgBottom3.png");
export const imgBottom4 = require("../assets/image.png");
export const group1 = require("../assets/group_.png");
export const group2 = require("../assets/group2.png");
export const group3 = require("../assets/group3.png");
export const camera = require("../assets/camera.png");
export const library = require("../assets/library.png");
export const mosaic = require("../assets/mosaic.png");
export const arrow1 = require("../assets/view_.png");
export const arrow2 = require("../assets/view_(1).png");
export const arrow3 = require("../assets/view_(2).png");
export const moment = require("../assets/moment.png");
export const baby = require("../assets/baby.png");
export const lady = require("../assets/lady.png");
export const couple = require("../assets/couple.png");
export const family = require("../assets/family.png");
export const girl = require("../assets/girl.png");
export const pin1 = require("../assets/pin1.png");
export const pin2 = require("../assets/pin2.png");
export const imgBottom5 = require("../assets/imgbottom4.png");
export const imgBottom6 = require("../assets/bottom6.png");
export const familyTree = require("../assets/tree.png");
export const leaf = require("../assets/leaf.png");
export const recipes = require("../assets/recipes.png");
export const outlineCheck = require("../assets/outlineCheck.png");
export const rightImg = require("../assets/rightImg.png");
export const recipeBannerEmpty = require("../assets/recipesEmptyImage.png");
export const recipeFeatureBg = require("../assets/recipeFeatureBg.png");
export const uploadImage = require("../assets/uploadImage.png");
export const organized = require("../assets/organized.png");
export const share = require("../assets/share.png");
export const cloudDone = require("../assets/cloudDone.png");
export const downArrow = require("../assets/downArrow.png");
export const recipeDesign1 = require("../assets/recipeDesign1.png");
export const recipeDesign2 = require("../assets/recipeDesign2.png");
export const recipeDesign3 = require("../assets/recipeDesign3.png");
export const recipeDesign4 = require("../assets/recipeDesign4.png");
export const recipeDesign5 = require("../assets/recipeDesign5.png");
export const recipeDesign6 = require("../assets/recipeDesign6.png");
export const recipeDesign7 = require("../assets/recipeDesign8.png");
export const recipeDesign8 = require("../assets/recipeDesign8.png");
export const recipeDesign9 = require("../assets/recipeDesign9.png");
export const recipeDesign10 = require("../assets/recipeDesign10.png");
export const familyTreeBanner = require("../assets/familyTreeBanner.png");
export const recipeBanner = require("../assets/recipeBanner.png");
export const mainMomments = require("../assets/mainMomments.png");
export const familyTreeCircle = require("../assets/familyTreeCircle.png");
export const familyTreePin = require("../assets/familyTreePin.png");
export const photosBanner = require("../assets/photosBanner.png"); 
export const uploadFilesImage = require("../assets/uploadFilesImage.png")
export const shareBeach = require("../assets/shareBeach.png");
export const photosImage = require("../assets/photosImage.png");
