import React from "react";

import {
    Box,
    Button,
    // Customizable Area Start
    TextField,
    IconButton,
    Typography,
    Radio,
    RadioProps,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Divider,
    FormControl,
    Select,
    MenuItem,
    Modal,
    InputAdornment,
} from "@material-ui/core";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CloseIcon from '@mui/icons-material/Close';
import { styled, withStyles } from "@material-ui/core/styles";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import moment from "moment";
// Customizable Area End

import SubscriptionController, {
    Props,
} from "./SubcriptionController.web";
import { BackArrowBlack, check, dollarSign, profile, publish, visaLogo, wallet, deleteIcon, trashIcon, failPayment, masterCard } from "./assets";
import HeaderDropdown from "../../../components/src/HeaderDropdown.web";
import CreditCardPayment from "../../../components/src/CreditCardPayment";
import { ErrorMessage, Formik } from "formik";

// Customizable Area Start
const GreenRadio = withStyles({
    root: {
        color: '78716C',
        '&$checked': {
            color: '#557C29',
        },
    },
    checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);

const bankNames = [
    "Bank of America",
    "Chase",
    "Wells Fargo",
    "Citi",
    "Capital One",
    "PNC Bank",
    "US Bank",
    "TD Bank",
    "BB&T",
    "SunTrust",
    "Fifth Third Bank",
    "HSBC",
    "Charles Schwab",
    "Ally Bank",
    "KeyBank",
    "Regions Bank",
    "Santander Bank",
    "BMO Harris Bank",
    "Huntington Bank",
    "M&T Bank",
    "Citizens Bank",
    "American Express",
    "Navy Federal Credit Union",
    "USAA",
    "Synchrony Bank",
    "Comerica",
    "Zions Bank",
    "First Republic Bank",
    "Silicon Valley Bank",
    "Discover Bank",
    "Goldman Sachs Bank USA",
    "Morgan Stanley Bank",
    "TD Ameritrade",
    "E*TRADE Bank",
    "Barclays Bank",
    "BancorpSouth",
    "BOK Financial",
    "Frost Bank",
    "Bank of the West",
    "BMO Financial Group",
    "Webster Bank",
    "Old National Bank",
    "UMB Financial Corporation",
    "People's United Financial",
    "FNB Corporation",
    "Pinnacle Financial Partners",
    "First National Bank",
    "Valley National Bank",
    "City National Bank",
    "East West Bank",
    "Cullen/Frost Bankers",
    "Synovus Bank",
    "Bank OZK",
    "Prosperity Bank",
    "Texas Capital Bank",
    "Wintrust Financial",
    "South State Bank",
    "Independent Bank",
    "Renasant Bank",
    "BankUnited",
    "First Citizens Bank",
    "United Community Bank",
    "Cadence Bank",
    "Western Alliance Bank",
    "Banner Bank",
    "Columbia Bank",
    "Great Western Bank",
    "Pacific Premier Bank",
    "First Horizon Bank",
    "Central Bank",
    "Southwest Bank",
    "Commerce Bank",
    "TowneBank",
    "Heritage Bank",
    "Bank of Hawaii",
    "BancFirst",
    "Glacier Bank",
    "Simmons Bank",
    "UMB Bank",
    "Heartland Bank",
    "Tri Counties Bank",
    "Trustmark National Bank",
    "First Financial Bank",
    "First Interstate Bank",
    "Provident Bank",
    "City Bank",
    "Hanmi Bank",
    "Mechanics Bank",
    "NBT Bank",
    "OceanFirst Bank",
    "Old Second National Bank",
    "Park National Bank",
    "Peoples Bank",
    "Pinnacle Bank",
    "PlainsCapital Bank",
    "PNC Bank",
    "Popular Bank",
    "Rabobank",
    "Raymond James Bank",
    "Republic Bank",
    "Rockland Trust",
    "Santander Bank",
    "Scotiabank",
    "Simmons Bank",
    "Southern Bank",
    "Sterling National Bank",
    "Synovus Bank",
    "TCF Bank",
    "TD Bank",
    "The Northern Trust Company",
    "Trustmark Bank",
    "UMB Bank",
    "Union Bank",
    "United Bank",
    "Valley National Bank",
    "Webster Bank",
    "Wells Fargo",
    "Western Alliance Bank",
    "Wintrust Bank",
    "Zions Bank"
];
// Customizable Area End

export default class Subscription extends SubscriptionController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <SubscriptionStyle>
                <HeaderDropdown data-test-id="header" editProfileClick={this.profileClickButton} subscriptionClick={this.subscriptionClickBtn} paymentClick={this.paymentClickBotton} />
                <Box style={{ padding: '30px', marginLeft: '40px', marginRight: '20px' }}>
                    <Breadcrumbs style={{ marginLeft: '70px' }} separator={<NavigateNextIcon fontSize="small" />}>
                        <Button className="breadcrumbsButton" color="inherit" data-test-id="breadcrumbsButton">
                            Settings
                        </Button>
                        <Typography className="breadcrumbsText">
                            Subscription
                        </Typography>
                    </Breadcrumbs>
                    <Box style={{ display: 'flex', marginTop: '15px' }}>
                        <Button className="backButton" data-test-id="backButton">
                            <img style={{ color: 'black' }} src={BackArrowBlack} alt="Back" />
                        </Button>
                        <Typography className="titleTypography">Your subscription</Typography>
                    </Box>
                </Box>
                <Box className="upperContainer">
                    <Box className="leftSideBox">
                        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography className="plansText">Plan & Pricing</Typography>
                            <Button className="manageButtonText" data-test-id="manageSubscriptionButton" onClick={this.handleNavigateManage}>Manage my subscription</Button>
                        </Box>
                        <Box className="detailsLeftBox">
                            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography className="indivText">{this.state.typeOfPlan}</Typography>
                                {this.state.active ? (
                                    <Typography className="activeText" style={{ backgroundColor: '#8CA74B' }}>Active</Typography>
                                ) : (
                                    <Typography className="activeText" style={{ backgroundColor: '#F87171' }}>Cancelled</Typography>
                                )}
                            </Box>
                            <Typography className="optionalText">The optimal plan for {this.state.typeOfPlan} projects</Typography>
                            <Typography className="storageText"><img style={{ borderRadius: '50px', marginRight: '10px' }} src={check} /> {this.state.storage} {this.state.storageUnit} of storage</Typography>
                            <Box style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                                <Typography className="priceTag">${this.state.amount}</Typography>
                                <Typography className="monthText">/ {this.state.interval}</Typography>
                            </Box>
                        </Box>
                        {this.state.active ? (
                            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px' }}>
                                <Typography className="billingText">Billing date</Typography>
                                <Typography className="descText" data-test-id="dateText">
                                    {moment(this.state.startDate).isValid()
                                        ? `The ${this.getOrdinal(moment(this.state.startDate).format('D'))} of each month`
                                        : 'The Invalid date of each month'}
                                </Typography>
                            </Box>
                        ) : (
                            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', marginTop: '10px' }}>
                                <Button className="addNewButton" data-test-id="reactivateBtn" onClick={this.handleReactivateModal}>Reactivate my subscription</Button>
                            </Box>
                        )}

                    </Box>
                    <Box className="rightSideBox">
                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                            <Typography className="paymentMethodText">Payment method</Typography>
                            <Button className="addNewButton" data-test-id="addNewButton" onClick={this.handleNewPaymentMethod}>Add new</Button>
                        </Box>
                        <Typography className="descMethodText">You can opt for either ACH (Automated Clearing House) or credit card as your payment method.</Typography>
                        <Box style={{ marginTop: '20px' }}>
                            {(() => {
                                const paymentMethodsAndAccounts = this.state.PaymentMethodData.slice();
                                this.state.subscriptionDetails.forEach((subscription: any, index: any) => {
                                    subscription.card_deatis.forEach((cardDetail: any, cardIndex: any) => {
                                        paymentMethodsAndAccounts.unshift({
                                            type: 'card',
                                            brand: cardDetail.brand,
                                            last4: cardDetail.last4,
                                            identifier: `Card${index}_${cardIndex}`,
                                        });
                                    });

                                    subscription.bank_details.forEach((bankAccount: any, bankIndex: any) => {
                                        paymentMethodsAndAccounts.unshift({
                                            type: 'ach',
                                            bankName: bankAccount.bank_name,
                                            last4: bankAccount.last4,
                                            identifier: `ACH${index}_${bankIndex}`,
                                        });
                                    });
                                });
                                const topTwoPaymentMethods = paymentMethodsAndAccounts.slice(0, 2);
                                return topTwoPaymentMethods.map((methodOrAccount, index) => {
                                    const isCard = methodOrAccount.type === 'card';
                                    const brand = methodOrAccount.brand || '';
                                    return (
                                        <div key={index}>
                                            <Box className={isCard ? "paymentBox1" : "paymentBox2"}>
                                                <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                    <GreenRadio
                                                        data-test-id="radioBtnTESTID"
                                                        checked={this.state.selectedPaymentMethod === methodOrAccount.identifier}
                                                        onChange={this.handlePaymentMethodChange}
                                                        value={methodOrAccount.identifier}
                                                    />
                                                    <Box style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                                        <Typography className={`visaCardText ${isCard && brand.toLowerCase() === 'visa' ? 'red' : 'blue'}`}>
                                                            {isCard
                                                                ? `${brand.charAt(0).toUpperCase() + brand.slice(1)} ending in **** ${methodOrAccount.last4}`
                                                                : `ACH ending in **** ${methodOrAccount.last4}`}
                                                        </Typography>
                                                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                            <Button className="setDefaultText">Set as default</Button>
                                                            <Typography className="line">|</Typography>
                                                            
                                                            <Button className="setDefaultBtn" data-test-id="editBtn3"
                                                            onClick={this.handleEditModal.bind(this,methodOrAccount.identifier,index)}
                                                            >
                                                            Edit</Button>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                {isCard ? (
                                                    brand === "visa" ? (
                                                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                            <img src={visaLogo} alt="Visa" style={{ marginRight: '10px', height: '13px' }} />
                                                        </Box>
                                                    ) : <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                        <img src={masterCard} alt="Visa" style={{ marginRight: '10px', height: '30px', width: '30px' }} />
                                                    </Box>
                                                ) : (
                                                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                        <img src={wallet} alt="ACH" style={{ height: '24px', width: '24px', marginRight: '10px' }} />
                                                    </Box>
                                                )}
                                            </Box>

                                            {this.state.methodModal && (
                                                <Modal
                                                    open={this.state.methodModal}
                                                    onClose={() => this.setState({ methodModal: false })}
                                                    aria-labelledby="modal-modal-title"
                                                    aria-describedby="modal-modal-description"
                                                >
                                                    <Box style={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                        maxWidth: 500,
                                                        width: '100%',
                                                        backgroundColor: '#fff',
                                                        boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.25)',
                                                        padding: 30
                                                    }}>
                                                        {this.state.selectedPaymentMethod === 'VisaCard' ? (
                                                            <Box>
                                                                <Box>
                                                                    <Box style={{ display: "flex", marginBottom: 20, justifyContent: "space-between" }}>
                                                                        <Typography style={{ fontSize: 20, fontWeight: 700, color: '#1C2110', fontFamily: 'Poppins' }}>Edit payment method</Typography>

                                                                        <IconButton
                                                                            aria-label="close"
                                                                            onClick={this.handleCardCloseModal}
                                                                            data-test-id="handleCloseSimulate"
                                                                            style={{
                                                                                top: 20,
                                                                                position: 'absolute',
                                                                                color: '#666',
                                                                                right: 0,
                                                                            }}
                                                                        >
                                                                            <CloseIcon />
                                                                        </IconButton>
                                                                    </Box>
                                                                </Box>
                                                                <Divider style={{
                                                                    margin: '10px -44px',
                                                                    width: 'calc(100% + 90px)',
                                                                }} />
                                                                <Typography style={{
                                                                    fontFamily: "Poppins",
                                                                    fontWeight: 700,
                                                                    fontSize: "16px",
                                                                    lineHeight: "24px",
                                                                    color: "#1C2110"
                                                                } as React.CSSProperties}>Cardholder name</Typography>
                                                                <div style={{ position: 'relative', marginBottom: '20px', marginTop: 5 }}>
                                                                    <input
                                                                        type="text"
                                                                        style={{
                                                                            width: '100%',
                                                                            borderRadius: '4px',
                                                                            padding: '20px 10px 16px 10px',
                                                                            border: '1px solid #ced4da',
                                                                            paddingRight: '40px',
                                                                            outline: 'none',
                                                                            fontWeight: 400,
                                                                            fontSize: '16px',
                                                                            fontFamily: 'Poppins',
                                                                            color: '#A8A29E',
                                                                        }}
                                                                        autoComplete="off"
                                                                        name="fullName"
                                                                        placeholder="Cardholder Name"
                                                                        data-test-id="inputSimulate"
                                                                    />
                                                                    <img
                                                                        src={profile}
                                                                        style={{
                                                                            position: 'absolute',
                                                                            top: '50%',
                                                                            transform: 'translateY(-50%)',
                                                                            right: '10px',
                                                                            width: '24px',
                                                                            height: '24px',
                                                                        }}
                                                                        alt="Profile"
                                                                    />
                                                                </div>
                                                                <Typography style={{
                                                                    fontFamily: "Poppins",
                                                                    fontWeight: 700,
                                                                    fontSize: "16px",
                                                                    lineHeight: "24px",
                                                                    color: "#1C2110"
                                                                }}>Card number</Typography>
                                                                <div

                                                                >
                                                                    <input
                                                                        type="text"
                                                                        placeholder="Enter card number"
                                                                        style={{
                                                                            fontSize: '16px',
                                                                            fontFamily: 'Poppins',
                                                                            color: '#A8A29E',
                                                                            fontWeight: 400,
                                                                            border: '1px solid #D6D3D1',
                                                                            borderRadius: '4px',
                                                                            marginTop: '5px',
                                                                            padding: '20px 10px 16px 10px',
                                                                            marginBottom: '20px',
                                                                            width: '100%',
                                                                            outline: 'none',
                                                                        }}
                                                                        data-test-id="fillCardsDetailsTest"
                                                                    />
                                                                </div>
                                                                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                                    <div style={{ flex: '1', marginRight: '10px' }}>
                                                                        <Typography style={{
                                                                            fontFamily: "Poppins",
                                                                            lineHeight: "24px",
                                                                            fontWeight: 700,
                                                                            fontSize: "16px",
                                                                            color: "#1C2110"
                                                                        }}>Expiration date</Typography>
                                                                        <div
                                                                            style={{
                                                                                marginBottom: '20px',
                                                                                marginTop: '5px',
                                                                                borderRadius: '4px',
                                                                                border: '1px solid #D6D3D1',
                                                                                padding: '8px 4px 8px 10px',
                                                                            }}
                                                                        >
                                                                            <input
                                                                                type="text"
                                                                                placeholder="MM/YY"
                                                                                style={{
                                                                                    fontSize: '16px',
                                                                                    color: '#A8A29E',
                                                                                    fontFamily: 'Poppins',
                                                                                    width: '100%',
                                                                                    fontWeight: 400,
                                                                                    border: 'none',
                                                                                    padding: '10px',
                                                                                    outline: 'none',
                                                                                    backgroundColor: 'transparent'
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ flex: '1', marginLeft: '10px' }}>
                                                                        <Typography style={{
                                                                            fontWeight: 700,
                                                                            fontFamily: "Poppins",
                                                                            fontSize: "16px",
                                                                            lineHeight: "24px",
                                                                            color: "#1C2110"
                                                                        }}>Security code</Typography>
                                                                        <div
                                                                            style={{
                                                                                marginBottom: '20px',
                                                                                border: '1px solid #D6D3D1',
                                                                                marginTop: '5px',
                                                                                borderRadius: '4px',
                                                                                padding: '8px 4px 8px 10px',
                                                                            }}
                                                                        >
                                                                            <input
                                                                                type="text"
                                                                                placeholder="CVC"
                                                                                style={{
                                                                                    fontSize: '16px',
                                                                                    color: '#A8A29E',
                                                                                    fontFamily: 'Poppins',
                                                                                    fontWeight: 400,
                                                                                    width: '100%',
                                                                                    border: 'none',
                                                                                    outline: 'none',
                                                                                    padding: '10px',
                                                                                    backgroundColor: 'transparent'
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Box>
                                                            </Box>

                                                        ) : (
                                                            <Box>
                                                                <Box>
                                                                    <Box style={{ display: "flex", justifyContent: "space-between", marginBottom: 20 }}>
                                                                        <Typography style={{ fontWeight: 700, fontSize: 20, color: '#1C2110', fontFamily: 'Poppins' }}>Edit payment method</Typography>

                                                                        <IconButton
                                                                            aria-label="close"
                                                                            onClick={this.handleCardCloseModal}
                                                                            data-tes-id="handleCloseSimulate"
                                                                            style={{
                                                                                position: 'absolute',
                                                                                right: 0,
                                                                                top: 20,
                                                                                color: '#666',
                                                                            }}
                                                                        >
                                                                            <CloseIcon />
                                                                        </IconButton>
                                                                    </Box>
                                                                </Box>
                                                                <Divider style={{
                                                                    width: 'calc(100% + 90px)',
                                                                    margin: '10px -44px',
                                                                }} />
                                                                <Typography style={{
                                                                    fontFamily: "Poppins",
                                                                    fontSize: "16px",
                                                                    fontWeight: 700,
                                                                    color: "#1C2110",
                                                                    lineHeight: "24px",
                                                                } as React.CSSProperties}>Full name</Typography>
                                                                <div style={{ position: 'relative', marginBottom: '20px', marginTop: 5 }}>
                                                                    <input
                                                                        type="text"
                                                                        style={{
                                                                            width: '100%',
                                                                            padding: '20px 10px 16px 10px',
                                                                            border: '1px solid #ced4da',
                                                                            paddingRight: '40px',
                                                                            outline: 'none',
                                                                            borderRadius: '4px',
                                                                            fontWeight: 400,
                                                                            fontSize: '16px',
                                                                            fontFamily: 'Poppins',
                                                                            color: '#A8A29E',
                                                                        }}
                                                                        autoComplete="off"
                                                                        name="fullName"
                                                                        placeholder="Enter Your Full Name"
                                                                        data-test-id="inputSimulate"
                                                                    />

                                                                </div>
                                                                <Typography style={{
                                                                    fontFamily: "Poppins",
                                                                    fontWeight: 700,
                                                                    fontSize: "16px",
                                                                    lineHeight: "24px",
                                                                    color: "#1C2110"
                                                                }}>Bank name</Typography>
                                                                <div>
                                                                    <input
                                                                        type="text"
                                                                        placeholder="Enter bank name"
                                                                        style={{
                                                                            fontSize: '16px',
                                                                            fontWeight: 400,
                                                                            color: '#A8A29E',
                                                                            fontFamily: 'Poppins',
                                                                            borderRadius: '4px',
                                                                            padding: '20px 10px 16px 10px',
                                                                            marginBottom: '20px',
                                                                            border: '1px solid #D6D3D1',
                                                                            marginTop: '5px',
                                                                            width: '100%',
                                                                            outline: 'none',
                                                                        }}
                                                                        data-test-id="fillCardsDetailsTest"
                                                                    />
                                                                </div>
                                                                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                                    <div style={{ flex: '1', marginRight: '10px' }}>
                                                                        <Typography style={{
                                                                            fontFamily: "Poppins",
                                                                            fontSize: "16px",
                                                                            lineHeight: "24px",
                                                                            fontWeight: 700,
                                                                            color: "#1C2110"
                                                                        }}>Acount number</Typography>
                                                                        <div
                                                                            style={{
                                                                                marginBottom: '20px',
                                                                                border: '1px solid #D6D3D1',
                                                                                borderRadius: '4px',
                                                                                marginTop: '5px',
                                                                                padding: '8px 4px 8px 10px',
                                                                            }}
                                                                        >
                                                                            <input
                                                                                type="text"
                                                                                placeholder="MM/YY"
                                                                                style={{
                                                                                    color: '#A8A29E',
                                                                                    fontSize: '16px',
                                                                                    fontFamily: 'Poppins',
                                                                                    fontWeight: 400,
                                                                                    border: 'none',
                                                                                    width: '100%',
                                                                                    outline: 'none',
                                                                                    padding: '10px',
                                                                                    backgroundColor: 'transparent'
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ flex: '1', marginLeft: '10px' }}>
                                                                        <Typography style={{
                                                                            fontWeight: 700,
                                                                            fontSize: "16px",
                                                                            fontFamily: "Poppins",
                                                                            lineHeight: "24px",
                                                                            color: "#1C2110"
                                                                        }}>Bank account type</Typography>
                                                                        <div
                                                                            style={{
                                                                                marginBottom: '20px',
                                                                                marginTop: '5px',
                                                                                border: '1px solid #D6D3D1',
                                                                                borderRadius: '4px',
                                                                                padding: '8px 4px 8px 10px',
                                                                            }}
                                                                        >
                                                                            <input
                                                                                type="text"
                                                                                placeholder="Bank type"
                                                                                style={{
                                                                                    fontSize: '16px',
                                                                                    color: '#A8A29E',
                                                                                    fontFamily: 'Poppins',
                                                                                    fontWeight: 400,
                                                                                    width: '100%',
                                                                                    border: 'none',
                                                                                    outline: 'none',
                                                                                    padding: '10px',
                                                                                    backgroundColor: 'transparent'
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                </Box>
                                                                <Typography style={{
                                                                    fontFamily: "Poppins",
                                                                    fontWeight: 700,
                                                                    fontSize: "16px",
                                                                    lineHeight: "24px",
                                                                    color: "#1C2110"
                                                                }}>Routing number</Typography>
                                                                <div

                                                                >
                                                                    <input
                                                                        type="text"
                                                                        placeholder="Enter routing number"
                                                                        style={{
                                                                            fontSize: '16px',
                                                                            color: '#A8A29E',
                                                                            fontFamily: 'Poppins',
                                                                            fontWeight: 400,
                                                                            border: '1px solid #D6D3D1',
                                                                            borderRadius: '4px',
                                                                            padding: '20px 10px 16px 10px',
                                                                            marginBottom: '20px',
                                                                            marginTop: '5px',
                                                                            width: '100%',
                                                                            outline: 'none',
                                                                        }}
                                                                        data-test-id="fillCardsDetailsTest"
                                                                    />
                                                                </div>

                                                            </Box>


                                                        )}
                                                        <Box style={{ display: 'flex' }}>
                                                            <img src={deleteIcon} style={{ width: 18, height: 20 }} />
                                                            <Typography
                                                                style={{ fontSize: 14, fontFamily: 'Poppins', fontWeight: 700, color: '#DC2626', cursor: 'pointer' }}
                                                                onClick={this.handleDeletePaymentMethodModal}
                                                                data-test-id="deletePayment"
                                                            >
                                                                Delete payment method
                                                            </Typography>
                                                        </Box>
                                                        <Divider style={{
                                                            width: 'calc(100% + 90px)',
                                                            margin: '10px -44px',
                                                            marginTop: 20
                                                        }} />

                                                        <Box style={{ display: 'flex', justifyContent: 'center', paddingBottom: 30, gap: 30 }}>

                                                            <Button style={{
                                                                backgroundColor: "#C2E176",
                                                                width: '220px',
                                                                borderRadius: '8px',
                                                                height: '56px',
                                                                marginTop: '32px'
                                                            }} 
                                                            onClick={this.handleCardCloseModal}
                                                            >
                                                                <Typography style={{
                                                                    fontFamily: "Poppins",
                                                                    fontSize: "16px",
                                                                    lineHeight: "24px",
                                                                    fontWeight: 700,
                                                                    color: "#8CA74B",
                                                                    textTransform: 'none'
                                                                } as any}>Cancel</Typography>
                                                            </Button>

                                                            <Button style={{
                                                                backgroundColor: "#8CA74B",
                                                                borderRadius: '8px',
                                                                marginTop: '32px',
                                                                height: '56px',
                                                                width: '195px',
                                                            }}>
                                                                <Typography style={{
                                                                    fontFamily: "Poppins",
                                                                    lineHeight: "24px",
                                                                    fontSize: "16px",
                                                                    fontWeight: 700,
                                                                    color: "#FFFFFF",
                                                                    textTransform: 'none'
                                                                } as any}>Save</Typography>


                                                            </Button>

                                                        </Box>
                                                    </Box>
                                                </Modal>
                                            )}
                                            <div>
                                                <Modal
                                                    open={this.state.sureDeleteModal}
                                                    onClose={this.handleDeleteCloseModal}
                                                    aria-labelledby="delete-payment-method-modal"
                                                    aria-describedby="delete-payment-method-description"
                                                >
                                                    <Box
                                                        style={{
                                                            position: 'absolute',
                                                            top: '50%',
                                                            maxWidth: 500,
                                                            transform: 'translate(-50%, -50%)',
                                                            width: '100%',
                                                            left: '50%',
                                                            backgroundColor: '#fff',
                                                            boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.25)',
                                                            padding: 30,
                                                        }}
                                                    >
                                                        <Box>

                                                            <Box style={{ display: "flex", justifyContent: "space-between", marginBottom: 20 }}>
                                                                <Typography style={{ fontSize: 20, fontWeight: 700, fontFamily: 'Poppins', color: '#1C2110' }}>Delete payment method</Typography>

                                                                <IconButton
                                                                    aria-label="close"
                                                                    onClick={this.handleSureDeleteModal}
                                                                    data-test-id="handlesuredelete"
                                                                    style={{
                                                                        top: 20,
                                                                        position: 'absolute',
                                                                        right: 0,
                                                                        color: '#666',
                                                                    }}
                                                                >
                                                                    <CloseIcon />
                                                                </IconButton>
                                                            </Box>
                                                        </Box>
                                                        <Divider style={{
                                                            width: 'calc(100% + 90px)',
                                                            margin: '10px -44px',
                                                        }} />

                                                        <Box style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginTop: 30 }}>
                                                            <div style={{
                                                                width: "130px",
                                                                height: "130px",
                                                                borderRadius: "50%",
                                                                backgroundColor: "#8CA74B",
                                                                justifyContent: "center",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                alignSelf: 'center'
                                                            }}>
                                                                <img
                                                                    src={trashIcon}
                                                                    className="image"
                                                                    alt="Centered"
                                                                    style={{ width: 85 }}
                                                                />
                                                            </div>
                                                        </Box>
                                                        <Typography style={{ fontFamily: 'Poppins', fontWeight: 700, fontSize: 20, color: '#1C2110', display: 'flex', textAlign: 'center', marginTop: 20 }}>
                                                            Are you sure you want to delete your payment method?
                                                        </Typography>
                                                        <Typography style={{ fontFamily: 'Poppins', fontWeight: 400, fontSize: 14, color: '#1C2110', display: 'flex', textAlign: 'center', marginTop: 20 }}>
                                                            If you proceed with deleting your payment method, you will have the option to add a new one afterwards.
                                                        </Typography>

                                                        <Divider style={{
                                                            width: 'calc(100% + 90px)',
                                                            marginTop: 20,
                                                            margin: '10px -44px',
                                                        }} />


                                                        <Box style={{ display: 'flex', justifyContent: 'center', paddingBottom: 20, gap: 30 }}>
                                                            <Button style={{
                                                                backgroundColor: '#F8FFE7',
                                                                borderRadius: '8px',
                                                                width: '195px',
                                                                height: '56px',
                                                                marginTop: '20px'
                                                            }} onClick={this.handleDeleteCloseModal}>
                                                                <Typography style={{
                                                                    fontFamily: "Poppins",
                                                                    fontSize: "16px",
                                                                    lineHeight: "24px",
                                                                    fontWeight: 700,
                                                                    color: "#8CA74B",
                                                                    textTransform: 'none'
                                                                } as any}>Keep it</Typography>
                                                            </Button>

                                                            <Button style={{
                                                                borderRadius: '8px',
                                                                height: '56px',
                                                                backgroundColor: "#8CA74B",
                                                                width: '195px',
                                                                marginTop: '20px'
                                                            }}>
                                                                <Typography style={{
                                                                    fontFamily: "Poppins",
                                                                    fontSize: "16px",
                                                                    fontWeight: 700,
                                                                    lineHeight: "24px",
                                                                    color: "#FFFFFF",
                                                                    textTransform: 'none'
                                                                } as any}
                                                                    onClick={() => {
                                                                    this.getBankOrCardDetails();
                                                                    }}
                                                                >Yes, delete it</Typography>

                                                            </Button>

                                                        </Box>

                                                    </Box>
                                                </Modal>
                                                <div>
                                                    <Modal
                                                        data-test-id="handlesuccessDefine"
                                                        open={this.state.successFullModal}
                                                        onClose={this.handleSuccessModal}
                                                        aria-describedby="delete-payment-method-description"
                                                        aria-labelledby="delete-payment-method-modal"
                                                    >
                                                        <Box
                                                            style={{
                                                                position: 'absolute',
                                                                left: '50%',
                                                                transform: 'translate(-50%, -50%)',
                                                                top: '50%',
                                                                maxWidth: 500,
                                                                width: '100%',
                                                                backgroundColor: '#fff',
                                                                padding: 30,
                                                                boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.25)',
                                                                borderBottomRightRadius: 50
                                                            }}
                                                        >
                                                            <Box>

                                                                <Box style={{ display: "flex", justifyContent: "space-between", marginBottom: 20 }}>
                                                                    <Typography style={{ fontSize: 20, fontWeight: 700, color: '#1C2110', fontFamily: 'Poppins' }}>Delete payment method</Typography>

                                                                    <IconButton
                                                                        aria-label="close"
                                                                        onClick={this.handleSuccessCloseModal}
                                                                        data-test-id="handleCloseSimulate6"
                                                                        style={{
                                                                            top: 20,
                                                                            right: 0,
                                                                            position: 'absolute',
                                                                            color: '#666',
                                                                        }}
                                                                    >
                                                                        <CloseIcon />
                                                                    </IconButton>
                                                                </Box>
                                                            </Box>
                                                            <Divider style={{
                                                                width: 'calc(100% + 90px)',
                                                                margin: '10px -44px',
                                                            }} />
                                                            <Box style={{ display: 'flex', marginTop: 30, justifyContent: 'center', flexDirection: 'column' }}>
                                                                <div style={{
                                                                    height: "130px",
                                                                    width: "130px",
                                                                    borderRadius: "50%",
                                                                    backgroundColor: "#34D399",
                                                                    justifyContent: "center",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    alignSelf: 'center'
                                                                }}>
                                                                    <img
                                                                        src={trashIcon}
                                                                        className="image"
                                                                        alt="Centered"
                                                                        style={{ width: 85 }}
                                                                    />
                                                                </div>
                                                            </Box>
                                                            <Box style={{ display: 'flex', justifyContent: 'center', width: '100%', maxWidth: '490' }}>
                                                                <Typography style={{ fontSize: 20, fontFamily: 'Poppins', fontWeight: 700, color: '#1C2110', display: 'flex', textAlign: 'center' }}>The payment method has been successfully deleted</Typography>
                                                            </Box>
                                                        </Box>
                                                    </Modal>
                                                </div>
                                                <div>
                                                    <Modal
                                                        open={this.state.deleteSuccessfulModal}
                                                        onClose={this.handleDeleteSuccessCloseModal}
                                                        data-test-id="deletesucessclose"
                                                        aria-describedby="delete-payment-method-description"
                                                        aria-labelledby="delete-payment-method-modal"
                                                    >
                                                        <Box
                                                            style={{
                                                                position: 'absolute',
                                                                top: '50%',
                                                                left: '50%',
                                                                maxWidth: 500,
                                                                transform: 'translate(-50%, -50%)',
                                                                width: '100%',
                                                                backgroundColor: '#fff',
                                                                padding: 30,
                                                                boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.25)',
                                                                borderBottomRightRadius: 50
                                                            }}
                                                        >
                                                            <Box>

                                                                <Box style={{ display: "flex", marginBottom: 20, justifyContent: "space-between" }}>
                                                                    <Typography style={{ fontSize: 20, fontWeight: 700, fontFamily: 'Poppins', color: '#1C2110' }}>Delete payment method</Typography>

                                                                    <IconButton
                                                                        aria-label="close"
                                                                        onClick={this.handleDeleteSuccessCloseModal}
                                                                        data-tes-id="handleCloseSimulate"
                                                                        style={{
                                                                            position: 'absolute',
                                                                            right: 0,
                                                                            top: 20,
                                                                            color: '#666',
                                                                        }}
                                                                    >
                                                                        <CloseIcon />
                                                                    </IconButton>
                                                                </Box>
                                                            </Box>
                                                            <Divider style={{
                                                                width: 'calc(100% + 90px)',
                                                                margin: '10px -44px',
                                                            }} />
                                                            <Box style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginTop: 30 }}>
                                                                <div style={{
                                                                    width: "130px",
                                                                    display: "flex",
                                                                    height: "130px",
                                                                    borderRadius: "50%",
                                                                    backgroundColor: "#F87171",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    alignSelf: 'center'
                                                                }}>
                                                                    <img
                                                                        src={trashIcon}
                                                                        alt="Centered"
                                                                        style={{ width: 85 }}
                                                                        className="image"
                                                                    />
                                                                </div>
                                                            </Box>
                                                            <Box style={{ display: 'flex', justifyContent: 'center', width: '100%', maxWidth: '490', marginTop: 20 }}>
                                                                <Typography style={{ fontSize: 20, fontFamily: 'Poppins', fontWeight: 700, color: '#1C2110', display: 'flex', textAlign: 'center' }}>Failed to delete the payment method</Typography>
                                                            </Box>
                                                            <Divider style={{
                                                                margin: '10px -44px',
                                                                width: 'calc(100% + 90px)',
                                                                marginTop: 20
                                                            }} />


                                                            <Box style={{ display: 'flex', justifyContent: 'center', paddingBottom: 20, gap: 30 }}>
                                                                <Button style={{
                                                                    backgroundColor: "#C2E176",
                                                                    borderRadius: '8px',
                                                                    height: '56px',
                                                                    width: '195px',
                                                                    marginTop: '20px'
                                                                }} onClick={this.handleDeleteSuccessCloseModal}>
                                                                    <Typography style={{
                                                                        fontFamily: "Poppins",
                                                                        fontWeight: 700,
                                                                        fontSize: "16px",
                                                                        lineHeight: "24px",
                                                                        color: "#8CA74B",
                                                                        textTransform: 'none'
                                                                    } as any}>Cancel</Typography>
                                                                </Button>

                                                                <Button style={{
                                                                    backgroundColor: "#8CA74B",
                                                                    borderRadius: '8px',
                                                                    height: '56px',
                                                                    width: '195px',
                                                                    marginTop: '20px'
                                                                }}>
                                                                    <Typography style={{
                                                                        fontFamily: "Poppins",
                                                                        fontWeight: 700,
                                                                        fontSize: "16px",
                                                                        lineHeight: "24px",
                                                                        color: "#FFFFFF",
                                                                        textTransform: 'none'
                                                                    } as any} onClick={this.handleNewPaymentMethod} data-test-id="buttonTestTryagain">Try again</Typography>

                                                                </Button>

                                                            </Box>

                                                        </Box>
                                                    </Modal>
                                                </div>


                                                <div>
                                                    <Modal
                                                        onClose={this.handleDeleteCloseModal}
                                                        open={this.state.deleteModal}
                                                        aria-labelledby="delete-payment-method-modal"
                                                        aria-describedby="delete-payment-method-description"
                                                    >
                                                        <Box
                                                            style={{
                                                                position: 'absolute',
                                                                top: '50%',
                                                                left: '50%',
                                                                width: '100%',
                                                                transform: 'translate(-50%, -50%)',
                                                                maxWidth: 500,
                                                                backgroundColor: '#fff',
                                                                boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.25)',
                                                                padding: 30,
                                                            }}
                                                        >
                                                            <Box>

                                                                <Box style={{ display: "flex", justifyContent: "space-between", marginBottom: 20 }}>
                                                                    <Typography style={{ fontSize: 20, fontWeight: 700, color: '#1C2110', fontFamily: 'Poppins' }}>Delete payment method</Typography>

                                                                    <IconButton
                                                                        aria-label="close"
                                                                        onClick={this.handleDeleteCloseModal}
                                                                        data-test-id="handleCloseSimulate4"
                                                                        style={{
                                                                            position: 'absolute',
                                                                            right: 0,
                                                                            top: 20,
                                                                            color: '#666',
                                                                        }}
                                                                    >
                                                                        <CloseIcon />
                                                                    </IconButton>
                                                                </Box>
                                                            </Box>
                                                            <Divider style={{
                                                                width: 'calc(100% + 90px)',
                                                                margin: '10px -44px',
                                                            }} />

                                                            <Box style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginTop: 30 }}>
                                                                <div style={{
                                                                    width: "130px",
                                                                    borderRadius: "50%",
                                                                    backgroundColor: "#8CA74B",
                                                                    height: "130px",
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    alignSelf: 'center'
                                                                }}>
                                                                    <img
                                                                        src={failPayment}
                                                                        className="image"
                                                                        alt="Centered"
                                                                        style={{ width: 85 }}
                                                                    />
                                                                </div>
                                                            </Box>
                                                            <Typography style={{ fontFamily: 'Poppins', fontWeight: 700, fontSize: 20, color: '#1C2110', display: 'flex', textAlign: 'center', marginTop: 20 }}>
                                                                You cannot delete the only payment method
                                                            </Typography>
                                                            <Typography style={{ fontFamily: 'Poppins', fontWeight: 400, fontSize: 14, color: '#1C2110', display: 'flex', textAlign: 'center', marginTop: 20 }}>
                                                                An active payment method is required. If you wish to delete it, you must add a new one to replace the current method.
                                                            </Typography>

                                                            <Divider style={{
                                                                margin: '10px -44px',
                                                                width: 'calc(100% + 90px)',
                                                                marginTop: 20
                                                            }} />


                                                            <Box style={{ display: 'flex', justifyContent: 'center', paddingBottom: 20, gap: 30 }}>
                                                                <Button style={{
                                                                    backgroundColor: "#C2E176",
                                                                    width: '220px',
                                                                    borderRadius: '8px',
                                                                    height: '56px',
                                                                    marginTop: '20px'
                                                                }} onClick={this.handleDeleteCloseModal}>
                                                                    <Typography style={{
                                                                        fontFamily: "Poppins",
                                                                        fontSize: "16px",
                                                                        lineHeight: "24px",
                                                                        fontWeight: 700,
                                                                        color: "#8CA74B",
                                                                        textTransform: 'none'
                                                                    } as any}>Cancel</Typography>
                                                                </Button>

                                                                <Button style={{
                                                                    backgroundColor: "#8CA74B",
                                                                    borderRadius: '8px',
                                                                    height: '56px',
                                                                    width: '220px',
                                                                    marginTop: '20px'
                                                                }}>
                                                                    <Typography style={{
                                                                        fontFamily: "Poppins",
                                                                        fontWeight: 700,
                                                                        fontSize: "16px",
                                                                        lineHeight: "24px",
                                                                        color: "#FFFFFF",
                                                                        textTransform: 'none'
                                                                    } as any} onClick={this.handleNewPaymentMethod}>Add new payment method</Typography>

                                                                </Button>

                                                            </Box>

                                                        </Box>
                                                    </Modal>
                                                </div>


                                            </div>
                                        </div>


                                    )


                                })
                            })
                                ()}


                        </Box>
                        {this.state.subscriptionDetails ? (
                            <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button className="viewAllButton">View more</Button>
                            </Box>
                        ) : (
                            null
                        )}
                    </Box>
                </Box>
                <Box className="lowerBox">
                    <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography className="paymentsTitleText">Payments </Typography>
                        <Button className="viewAllButton" onClick={this.handleNavigateView}>View all</Button>
                    </Box>
                    <TableContainer style={{ margin: '20px 0', borderRadius: '8px', border: "1px solid #E7E5E4" }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className="tableTitleText">Order number</TableCell>
                                    <TableCell className="tableTitleText">Amount</TableCell>
                                    <TableCell className="tableTitleText">Type of Plan</TableCell>
                                    <TableCell className="tableTitleText">Payment date</TableCell>
                                    <TableCell className="tableTitleText">Payment method</TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.subscriptionDetails.map((subscription: any) => (
                                    <TableRow key={subscription.stripe_subscription_id}>
                                        <TableCell className="tableDescText">{subscription.stripe_subscription_id}</TableCell>
                                        <TableCell className="tableDescText">{subscription.amount}</TableCell>
                                        <TableCell className="tableDescText">{subscription.plan_name}</TableCell>
                                        <TableCell className="tableDescText">{moment(subscription.start_date).format('MMM DD, YYYY')}</TableCell>
                                        <TableCell><img src={visaLogo} alt="Visa" style={{ marginRight: '10px', height: '13px' }} /> **** {subscription.cardNumber}</TableCell>
                                        <TableCell>
                                            <Button className="seeDetailsButton" data-test-id="seeDetailsButton" onClick={this.handleSeeDetails}> See Details</Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

                <Dialog
                    data-test-id="handleCloseNewPaymentMethod"
                    open={this.state.addNewPaymentModal}
                    onClose={this.handleCloseNewPaymentMethod}
                    className="dialogContainer"
                    PaperProps={{
                        style: {
                            width: '600px',
                            borderRadius: 8,
                            borderBottomRightRadius: 50,
                        },
                    }}
                >
                    <DialogMainContent>
                        <Box className="headerBox">
                            <Typography style={{ fontSize: 20, fontWeight: 700, fontFamily: 'Poppins', color: '#1C2110' }}>Add a new payment method</Typography>
                            <IconButton
                                data-test-id='deleteIconDialog'
                                className="closeIcon"
                                onClick={this.handleCloseNewPaymentMethod}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Divider style={{ marginTop: '25px', marginBottom: '25px', color: '#E7E5E4', width: 'calc(100% + 90px)', margin: '20px -44px' }} />
                        <Box style={{
                            marginTop: 20,
                            backgroundColor: "#f7f0f0",
                            width: "100%",
                            padding: 2,
                            borderRadius: 40
                        }}>
                            <ToggleButtonGroup
                                value={this.state.paymentMethod}
                                exclusive
                                onChange={this.handleToggleButton}
                                aria-label="payment method"
                                fullWidth
                                data-test-id="toggleButton"

                            >
                                <ToggleButton
                                    value="bankTransfer"
                                    aria-label="bank transfer"
                                    style={{
                                        fontSize: 16,
                                        textTransform: 'none',
                                        fontFamily: 'Poppins',
                                        borderRadius: 30,
                                        borderColor: '#f7f0f0',
                                        fontWeight: 700,
                                        backgroundColor: this.state.paymentMethod === 'bankTransfer' ? '#8CA74B' : '#f7f0f0',
                                        color: this.state.paymentMethod === 'bankTransfer' ? '#FAFAF9' : '#78716C'
                                    }}
                                >
                                    Bank transfer (ACH)
                                </ToggleButton>
                                <ToggleButton value="creditCard" aria-label="credit card" style={{
                                    fontSize: 16,
                                    fontWeight: 700,
                                    fontFamily: 'Poppins',
                                    borderColor: '#f7f0f0',
                                    textTransform: 'none',
                                    borderRadius: 30,
                                    backgroundColor: this.state.paymentMethod === 'creditCard' ? '#8CA74B' : '#f7f0f0',
                                    color: this.state.paymentMethod === 'creditCard' ? '#ffffff' : '#78716C'
                                }}>
                                    Credit Card
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Box>

                        {this.state.paymentMethod === 'creditCard' && (
                            <CreditCardPayment handleCloseModal={this.handleCloseModal}
                                paymentCallback={(id: any) => { this.createSubscriptionApiCall(id) }}
                                buttonText="Save payment method"
                                creditCardText=""
                            />
                        )}

                        {this.state.paymentMethod === 'bankTransfer' && (
                            <Formik
                                data-test-id="formikProfileForm"
                                enableReinitialize
                                initialValues={{
                                    accountHolderName: this.state.accountHolderName,
                                    routingNumber: this.state.routingNumber,
                                    accountType: this.state.accountType,
                                    accountNumber: this.state.accountNumber,
                                    bankName: this.state.bankName,
                                }}
                                validationSchema={this.upiDetialsSchema}
                                onSubmit={(values) => {
                                    this.createSubscriptionAchApiCall(values);
                                }}
                            >
                                {({ handleSubmit, handleChange, values, setFieldValue }) => (
                                    <form onSubmit={handleSubmit} style={{ marginTop: 30 }} >
                                        <div style={{ marginTop: 30 }}>
                                            <Typography className="accountDetailsText">Fill in this form with your bank account details.</Typography>
                                            <Typography className="titleTexts">Full Name</Typography>
                                            <TextField
                                                value={values.accountHolderName}
                                                onChange={handleChange}
                                                data-test-id="fullNameInput"
                                                type="text"
                                                style={{ marginBottom: '20px', marginTop: 5 }}
                                                autoComplete="off"
                                                fullWidth
                                                name="accountHolderName"
                                                placeholder="Your Name"
                                                variant="outlined"
                                            />
                                            <ErrorMessage name="accountHolderName" component="div" className="errorMessage" />
                                            <div style={{ marginTop: '10px' }}>
                                                <Typography className="titleTexts" style={{ marginBottom: '-8px' }}>Bank name</Typography>
                                                <FormControl variant="outlined" fullWidth margin="normal">
                                                    <Select
                                                        style={{ marginBottom: '10px' }}
                                                        value={values.bankName}
                                                        data-test-id="bankfield"
                                                        onChange={(event) => setFieldValue('bankName', event.target.value)}
                                                        displayEmpty
                                                        name="bankName"
                                                        variant="outlined"
                                                        autoComplete="off"
                                                    >
                                                        <MenuItem value="" disabled>
                                                            Select bank name
                                                        </MenuItem>
                                                        {bankNames.map((bank, index) => (
                                                            <MenuItem key={index} value={bank}>
                                                                {bank}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                <ErrorMessage name="bankName" component="div" className="errorMessage" />
                                            </div>
                                            <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '20px' }}>
                                                <div style={{ flex: '1', marginRight: '10px' }}>
                                                    <Typography className="titleTexts">Account number</Typography>
                                                    <TextField
                                                        value={values.accountNumber}
                                                        onChange={handleChange}
                                                        data-test-id="accountNumberField"
                                                        type="text"
                                                        style={{ marginBottom: '20px', marginTop: 5 }}
                                                        autoComplete="off"
                                                        fullWidth
                                                        name="accountNumber"
                                                        placeholder="Your account number"
                                                        variant="outlined"
                                                    />
                                                    <ErrorMessage name="accountNumber" component="div" className="errorMessage" />
                                                </div>
                                                <div style={{ flex: '1', marginLeft: '10px' }}>
                                                    <Typography className="titleTexts">Bank account type</Typography>
                                                    <FormControl style={{ marginBottom: '20px', marginTop: 5 }} fullWidth>
                                                        <Select
                                                            value={values.accountType}
                                                            data-test-id="bankAccTypeField"
                                                            onChange={(event) => setFieldValue('accountType', event.target.value)}
                                                            name="accountType"
                                                            variant="outlined"
                                                            autoComplete="off"
                                                            fullWidth
                                                            displayEmpty
                                                        >
                                                            <MenuItem value="" disabled>Select bank account type </MenuItem>
                                                            <MenuItem value="individual">Individual</MenuItem>
                                                            <MenuItem value="company">Company</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    <ErrorMessage name="accountType" component="div" className="errorMessage" />
                                                </div>
                                            </Box>
                                            <Typography className="titleTexts" style={{ marginTop: '10px' }}>Routing number</Typography>
                                            <TextField
                                                value={values.routingNumber}
                                                onChange={handleChange}
                                                data-test-id="routingNumberField"
                                                type="text"
                                                style={{ marginBottom: '20px', marginTop: 5 }}
                                                autoComplete="off"
                                                fullWidth
                                                name="routingNumber"
                                                placeholder="Your routing number"
                                                variant="outlined"
                                            />
                                            <ErrorMessage name="routingNumber" component="div" className="errorMessage" />
                                        </div>
                                        <Divider style={{ marginTop: '25px', marginBottom: '25px', color: '#E7E5E4', width: 'calc(100% + 90px)', margin: '20px -44px' }} />
                                        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Button className="cancelBtn" onClick={this.handleCloseNewPaymentMethod} data-test-id="cancelButton">Cancel</Button>
                                            <Button className="saveBtn" type="submit" data-test-id="continueButton">Save payment method</Button>
                                        </Box>
                                    </form>
                                )}
                            </Formik>
                        )}
                    </DialogMainContent>
                </Dialog>

                <Dialog
                    className="dialogContainer"
                    open={this.state.openSuccessModal}
                    onClose={this.handleCloseModal}
                    PaperProps={{
                        style: {
                            position: 'relative',
                            height: 'auto',
                            width: '510px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            borderRadius: 8,
                            borderBottomRightRadius: 50,
                        },
                    }}
                >
                    <DialogTitle>
                        <Typography style={{ fontSize: 20, fontWeight: 700, fontFamily: 'Poppins', color: '#1C2110' }}>Add a new payment method</Typography>
                        <IconButton
                            data-test-id='deleteIcon'
                            onClick={this.handleCloseModal}
                            style={{
                                right: 8,
                                position: 'absolute',
                                color: '#666',
                                top: 8,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <Divider />
                    <DialogContent style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Box style={{
                                backgroundColor: '#34D399',
                                textAlign: 'center',
                                height: '130px',
                                borderRadius: '80px',
                                display: 'flex',
                                width: '130px',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <img src={dollarSign} style={{ color: 'white', height: '65px', width: '65px' }} />
                            </Box>
                        </div>
                        <Box style={{ textAlign: 'center', marginBottom: '20px', marginLeft: '10px', marginRight: '10px', marginTop: '40px' }}>
                            <Typography style={{
                                fontFamily: "Poppins",
                                fontWeight: 700,
                                fontSize: "20px",
                                lineHeight: "28px",
                                color: "#1C2110",
                                alignItems: 'center'
                            }} >Payment Method added</Typography>
                            <Typography style={{
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "16px",
                                lineHeight: "24px",
                                color: "#1C2110",
                                alignItems: 'center',
                                marginTop: '10px'
                            }}>Your payment method bank transfer (ACH) has been added successfully. </Typography>
                        </Box>
                    </DialogContent>
                </Dialog>

                <Dialog open={this.state.isPaymentModelOpen} data-test-id="dilogcloseButton" PaperProps={{
                    style: {
                        height: 555,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        borderRadius: 8,
                        borderBottomRightRadius: 50,
                    },
                }} >
                    <DialogTitle style={{ fontSize: 20, fontWeight: 700, fontFamily: 'Poppins' }}>
                        <Typography style={{ fontSize: 20, fontWeight: 700, fontFamily: 'Poppins', color: '#1C2110' }}>Add a new payment method</Typography>
                        <IconButton onClick={this.handlCloseFailPaymentMethod} aria-label="close" style={{ position: 'absolute', right: 8, top: 8, color: '#666' }}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <Divider />
                    <DialogContent style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Box style={{
                                borderRadius: '80px',
                                textAlign: 'center',
                                backgroundColor: '#F87171',
                                width: '130px',
                                height: '130px',
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                                <img src={dollarSign} style={{ color: 'white', height: '65px', width: '65px' }} />
                            </Box>
                        </div>
                        <Box style={{ textAlign: 'center', marginLeft: '10px', marginBottom: '20px', marginRight: '10px', marginTop: '40px' }}>
                            <Typography style={{
                                fontWeight: 700,
                                fontFamily: "Poppins",
                                lineHeight: "28px",
                                fontSize: "20px",
                                alignItems: 'center',
                                color: "#1C2110"
                            }} >Failed to add Payment Method</Typography>
                            <Typography style={{
                                fontWeight: 400,
                                fontFamily: "Poppins",
                                lineHeight: "24px",
                                color: "#1C2110",
                                alignItems: 'center',
                                marginTop: '10px',
                                fontSize: "16px"
                            }}>There is an issue with the ACH transaction associated with your account. The payment method could not be added at this time. </Typography>
                        </Box>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ display: 'flex', justifyContent: 'center', paddingBottom: 30, marginTop: '30px' }}>
                        <Button onClick={this.handlCloseFailPaymentMethod} style={{
                            border: '1px solid #C2E176',
                            backgroundColor: '#F8FFE7',
                            width: '300px',
                            height: '56px',
                            fontSize: "16px",
                            fontWeight: 700,
                            textTransform: "none",
                            fontFamily: "Poppins",
                            lineHeight: "24px",
                            color: '#8CA74B'
                        }}>Cancel</Button>
                        <Button style={{
                            backgroundColor: '#8CA74B',
                            width: '300px',
                            height: '56px',
                            fontSize: "16px",
                            fontWeight: 700,
                            textTransform: "none",
                            fontFamily: "Poppins",
                            lineHeight: "24px",
                            color: 'white'
                        }} onClick={this.handleNewPaymentMethod} >Try again </Button>
                    </DialogActions>
                </Dialog>

                <Dialog className="dialogContainer" data-test-d="handleCloseChangeModal" onClose={this.handleCloseReactivateModal} open={this.state.isReactivateModal} PaperProps={{
                    style: {
                        position: 'relative',
                        height: 'auto',
                        width: '680px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        borderRadius: 8,
                        borderBottomRightRadius: 50,
                    }
                }} >
                    <DialogTitle>
                        <Typography style={{ fontSize: 20, fontWeight: 700, fontFamily: 'Poppins', color: '#1C2110' }}>Reactivate my subscription</Typography>
                        <Typography style={{ fontSize: 16, fontWeight: 400, fontFamily: 'Poppins', color: '#57534E' }}>We are happy you come back and receive the benefits of your subscription. </Typography>
                        <IconButton data-test-id='deleteCloseBtn' onClick={this.handleCloseReactivateModal} style={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: '#666',
                        }}><CloseIcon /></IconButton>
                    </DialogTitle>
                    <Divider />
                    <DialogBoxContent className="dialogContainerMain">
                        <Typography style={{ fontSize: 18, fontWeight: 700, fontFamily: 'Poppins', color: '#5D6063' }}>Your subscription plan:</Typography>
                        <Box style={{ width: '190px', margin: '10 0 20 0' }}>
                            {this.state.plans.filter((plan: any) => plan.product_name === this.state.typeOfPlan).map((plan: any) => (
                                <Box key={plan.plan_id} className="planConatiners" onClick={() => this.handleBoxClick(plan)}
                                    style={{
                                        ...this.state.selectedPlan === plan ?
                                            { border: '1px solid #C2E176', backgroundColor: '#F8FFE7' } :
                                            { border: '1px solid #D6D3D1' },
                                        cursor: 'pointer'
                                    }}>
                                    <Typography className="individualsTitle">{plan.product_name}</Typography>
                                    <Typography className="storageDialogText">
                                        <img src={check} style={{ marginRight: '8px' }} />
                                        {plan.storage} {plan.storageUnit} of storage
                                    </Typography>
                                    <Typography className="priceTag">
                                        ${(plan.amount / 100).toFixed(2)} <span className="monthTag">/{plan.interval}</span>
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                        <Box >
                            <Typography style={{ fontSize: 16, fontWeight: 400, fontFamily: 'Poppins', color: '#57534E', marginBottom: '20px' }}>Would you like to change your plan?</Typography>
                            {this.state.plans.filter((plan: any) => plan.product_name !== this.state.typeOfPlan).map((plan: any) => (
                                <Box key={plan.plan_id} className="planConatiners" onClick={() => this.handleBoxClick(plan)}
                                    style={{
                                        ...this.state.selectedPlan === plan ?
                                            { border: '1px solid #C2E176', backgroundColor: '#F8FFE7' } :
                                            { border: '1px solid #D6D3D1' },
                                        cursor: 'pointer'
                                    }}>
                                    <Typography className="individualsTitle">{plan.product_name}</Typography>
                                    <Typography className="storageDialogText">
                                        <img src={check} style={{ marginRight: '8px' }} />{plan.product_name}
                                    </Typography>
                                    <Typography className="priceTag">${(plan.amount / 100).toFixed(2)} <span className="monthTag">/{plan.interval}</span></Typography>
                                </Box>
                            ))}
                        </Box>
                    </DialogBoxContent>
                    <Divider />
                    <DialogActions style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: 30, margin: '20px 10px 20px 10px' }}>
                        <Button onClick={this.handleCloseReactivateModal} style={{
                            border: '1px solid #C2E176',
                            backgroundColor: '#F8FFE7',
                            width: '300px',
                            height: '56px',
                            fontSize: "16px",
                            fontWeight: 700,
                            textTransform: "none",
                            fontFamily: "Poppins",
                            lineHeight: "24px",
                            color: '#8CA74B'
                        }}>Cancel</Button>
                        <Button data-test-id="handleReactivateSubscriptionBtn"
                            onClick={this.handleReactivateSubscription}
                            style={{
                                backgroundColor: '#8CA74B',
                                width: '300px',
                                height: '56px',
                                fontSize: "16px",
                                fontWeight: 700,
                                textTransform: "none",
                                fontFamily: "Poppins",
                                lineHeight: "24px",
                                color: 'white'
                            }}>Reactivate my subscription</Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    data-test-id="handleCloseSuccessReactivateModal"
                    className="dialogContainer"
                    open={this.state.isSuccessReactivateModal}
                    onClose={this.handleCloseSuccessReactivateModal}
                    PaperProps={{
                        style: {
                            position: 'relative',
                            height: 'auto',
                            width: '510px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            borderRadius: 8,
                            borderBottomRightRadius: 50,
                        },
                    }}
                >
                    <DialogTitle>
                        <Typography style={{ fontSize: 20, fontWeight: 700, fontFamily: 'Poppins', color: '#1C2110' }}>Subscription changed</Typography>
                        <IconButton
                            data-test-id='deleteIconDialogBtn'
                            onClick={this.handleCloseSuccessReactivateModal}
                            style={{
                                position: 'absolute',
                                color: '#666',
                                top: 8,
                                right: 8,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <Divider />
                    <DialogContent style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                        <div style={{ justifyContent: 'center', display: 'flex' }}>
                            <Box style={{
                                marginTop: '20px',
                                textAlign: 'center',
                                borderRadius: '80px',
                                backgroundColor: '#34D399',
                                height: '130px',
                                display: 'flex',
                                width: '130px',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <img src={publish} style={{ height: '65px', width: '65px', color: 'white' }} />
                            </Box>
                        </div>
                        <Box style={{ textAlign: 'center', margin: '40px 10px 20px 10px' }}>
                            <Typography style={{
                                fontWeight: 700,
                                fontFamily: "Poppins",
                                fontSize: "20px",
                                alignItems: 'center',
                                lineHeight: "28px",
                                color: "#1C2110",
                            }} >Your subscription has been reactivated</Typography>
                            <Typography style={{
                                fontWeight: 400,
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                color: "#1C2110",
                                alignItems: 'center',
                                lineHeight: "24px",
                                marginBottom: '20px',
                                marginTop: '20px'
                            }}>You have reactivated your subscription to {this.state.planName} plan. Your billing date will be
                                {moment(this.state.endDate).isValid()
                                    ? `the ${this.getOrdinal(moment(this.state.endDate).format('D'))} of each month.`
                                    : 'The Invalid date of each month.'}
                            </Typography>
                        </Box>
                    </DialogContent>
                </Dialog>

            </SubscriptionStyle >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const DialogBoxContent = styled("div")({
    padding: '25px',
    marginLeft: '10px',
    "& .individualsTitle": {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#1C2110",
        marginBottom: '5px'
    },
    "& .planConatiners": {
        padding: '15px',
        borderRadius: '8px',
        border: '1px solid #D6D3D1'
    },
    "& .dialogContainerMain": {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    "& .storageDialogText": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "18px",
        color: "#1C2110",
        alignItems: 'center',
        display: 'flex',
        marginBottom: '8px'
    },
    "& .priceTag": {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "24px",
        lineHeight: "32px",
        color: "#1C2110",
        letterSpacing: "-0.5%",
    },
    "& .monthTag": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "22px",
        color: '#78716C'
    }
});


const DialogMainContent = styled("div")({
    padding: '30px',
    height: 'auto',
    "& .titleTexts": {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#1C2110"
    },
    "& .cancelBtn": {
        borderRadius: '8px',
        border: '1px solid #C2E176',
        backgroundColor: '#F8FFE7',
        width: '209px',
        height: '56px',
        '& .MuiButton-label': {
            textTransform: "none",
            fontSize: "16px",
            fontWeight: 700,
            color: '#8CA74B',
            fontFamily: "Poppins",
            lineHeight: "24px",
        }
    },
    "& .saveBtn": {
        width: '223px',
        backgroundColor: '#8CA74B',
        borderRadius: '8px',
        height: '56px',
        '& .MuiButton-label': {
            fontWeight: 700,
            fontSize: "16px",
            fontFamily: "Poppins",
            textTransform: "none",
            color: 'white',
            lineHeight: "24px",
        }
    },
    "& .headerBox": {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    "& .accountDetailsText": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#1C2110",
        marginBottom: '20px'
    },
    "& .errorMessage": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "18px",
        color: '#DC2626',
        marginTop: '-12px'
    }
});

const SubscriptionStyle = styled("div")({
    "& .breadcrumbsButton": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "22px",
        color: "#57534E",
        "& .MuiButton-label": {
            textTransform: "none",
        },
    },
    "& .breadcrumbsText": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "22px",
        color: "#1C2110",
    },
    "& .titleTypography": {
        fontWeight: 700,
        fontFamily: "Poppins",
        lineHeight: "40px",
        fontSize: "30px",
        color: "#1C2110",
        letterSpacing: "-0.5%",
    },
    "& .upperContainer": {
        display: 'flex',
        justifyContent: 'space-between',
        marginLeft: '15%',
        marginRight: '15%',
        gap: '20px'
    },
    "& .leftSideBox": {
        borderRadius: '8px 8px 32px 8px',
        boxShadow: "0 0 10px 5px rgba(0, 0, 0, 0.07)",
        width: '500px',
        height: '310px',
        padding: '40px'
    },
    "& .plansText": {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "20px",
        lineHeight: "28px",
        color: "#1C2110",
    },
    "& .manageButtonText": {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "22px",
        color: "#557C29",
        "& .MuiButton-label": {
            textTransform: "none",
            fontFamily: "Poppins",
            fontWeight: 700,
            fontSize: "14px",
            lineHeight: "22px",
            color: "#557C29",
        },
    },
    "& .detailsLeftBox": {
        borderRadius: '8px',
        border: '1px solid #E2E8F0',
        padding: '15px',
        marginTop: '20px'
    },
    "& .indivText": {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#1C2110",
    },
    "& .activeText": {
        borderRadius: '8px',
        color: 'white',
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "12px",
        lineHeight: "18px",
        width: '70px',
        height: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    "& .optionalText": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#5D6063"
    },
    "& .storageText": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "18px",
        color: "#1C2110",
        display: 'flex',
        alignItems: 'center',
        marginTop: '20px'
    },
    "& .priceTag": {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "30px",
        lineHeight: "40px",
        color: '#1C2110',
        letterSpacing: '-1.5%',
    },
    "& .monthText": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "22px",
        color: '#78716C'
    },
    "& .billingText": {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "22px",
        color: '#1C2110'
    },
    "& .descText": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "22px",
        color: '#5D6063'
    },
    "& .rightSideBox": {
        borderRadius: '8px 8px 32px 8px',
        width: '500px',
        height: '310px',
        padding: '40px',
        boxShadow: "0 0 10px 5px rgba(0, 0, 0, 0.07)",
    },
    "& .paymentMethodText": {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "20px",
        lineHeight: "28px",
        color: '#1C2110',
    },
    "& .addNewButton": {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "22px",
        color: '#557C29',
        "& .MuiButton-label": {
            textTransform: 'none'
        },
    },
    "& .descMethodText": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        color: '#5D6063',
        marginTop: '5px'
    },
    "& .paymentBox1": {
        display: 'flex',
        justifyContent: 'space-between',
        height: '60px',
        borderRadius: '8px',
        backgroundColor: '#F8FFE7',
        padding: '10px',
        border: '1px solid #C2E176',
        marginBottom: '15px'
    },
    "& .paymentBox2": {
        display: 'flex',
        justifyContent: 'space-between',
        height: '60px',
        borderRadius: '8px',
        border: '1px solid #D6D3D1',
        padding: '10px',
        marginBottom: '15px'
    },
    "& .visaCardText": {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#000000",
    },
    "& .setDefaultText": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "22px",
        color: "#5D6063",
        "& .MuiButton-label": {
            textTransform: 'none'
        },
    },
    "& .setDefaultBtn": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "22px",
        color: "#5D6063",
        marginLeft: '-10px',
        "& .MuiButton-label": {
            textTransform: 'none'
        },
    },
    "& .line": {
        color: "#5D6063",
    },
    "& .lowerBox": {
        marginLeft: '15%',
        marginRight: '15%',
        borderRadius: '8px 8px 32px 8px',
        padding: '40px',
        boxShadow: "0 0 10px 5px rgba(0, 0, 0, 0.07)",
        marginTop: '46px',
        marginBottom: '5%'
    },
    "& .paymentsTitleText": {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "20px",
        lineHeight: "28px",
        color: "#1C2110",
    },
    "& .viewAllButton": {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "22px",
        color: "#557C29",
        "& .MuiButton-label": {
            textTransform: 'none'
        },
    },
    "& .tableTitleText": {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "22px",
        color: "#1C2110",
    },
    "& .tableDescText": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "18px",
        color: "#1C2110",
    },
    "& .seeDetailsButton": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "18px",
        color: "#557C29",
        "& .MuiButton-label": {
            textTransform: 'none'
        },
    }
});
// Customizable Area End