import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export interface INavigateTo {
    id?: string;
    props: unknown;
    screenName: string;
    raiseMessage?: Message;
}

export function navigateTo({
    id,
    props,
    screenName,
    raiseMessage,
}: INavigateTo) {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
   
    if (id) {
        message.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
    }
    if (raiseMessage) {
        message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    }
   
    runEngine.sendMessage(message.id, message);
}
