import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import * as Yup from 'yup';
import moment from 'moment';


interface Address {
  city: string | null;
  country: string | null;
  line1: string | null;
  line2: string | null;
  postal_code: string | null;
  state: string | null;
}

interface Card {
  brand: string;
  last4: string;
  exp_month: number;
  exp_year: number;
}

interface PaymentMethod {
  id: string;
  type: string;
  card: Card;
  billing_details: {
    address: Address;
    email: string | null;
    name: string;
    phone: string | null;
  };
}

interface Plan {
  id: string;
  object: string;
  active: boolean;
  amount: number;
  amount_decimal: string;
  billing_scheme: string;
  created: number;
  currency: string;
  interval: string;
  interval_count: number;
  nickname: string | null;
  product: string;
}

interface Price {
  id: string;
  object: string;
  active: boolean;
  billing_scheme: string;
  created: number;
  currency: string;
  recurring: {
    interval: string;
    interval_count: number;
  };
  unit_amount: number;
  unit_amount_decimal: string;
}

interface LineItem {
  id: string;
  object: string;
  amount: number;
  currency: string;
  description: string;
  plan: Plan;
  price: Price;
  quantity: number;
}

interface Invoice {
  id: string;
  object: string;
  amount_due: number;
  amount_paid: number;
  currency: string;
  customer_email: string;
  lines: {
    object: string;
    data: LineItem[];
  };
  status: string;
  total: number;
  number: string;
}

interface Subscription {
  id: number;
  stripe_subscription_id: string;
  start_date: string;
  end_date: string;
  plan_name: string;
  interval: string;
  amount: string;
  active: boolean;
  total_amount: number;
  storage: string;
  payment_method: PaymentMethod;
  billing_date: number;
  description: string
  storage_unit: string;
}

interface UserData {
  current_subscription: Subscription;
  account_email: string;
  latest_invoice: Invoice;
}

interface PlanAndPricing {
  id: number;
  name: string;
  details: string;
  storage: number;
  price: string;
  storage_unit: string;
  stripe_product_id: string;
  stripe_price_id: string;
  created_at: string;
  updated_at: string;
}


interface ProductData {
  id: number;
  name: string;
  details: string;
  storage: number;
  price: string;
  storage_unit: string;
  stripe_product_id: string;
  stripe_price_id: string;
  created_at: string;
  updated_at: string;
}



// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  stripe: any;
  elements: any;
  // Customizable Area End
}


interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  checked: boolean;
  expanded: any;
  modalOpen: boolean;
  paymentMethod: 'creditCard' | 'bankTransfer';
  showPaymentMethod: any;
  cardBrand: any;
  cardHolderName: any;
  stripeToken: any;
  cardType: any;
  accountHolderName: string;
  bankName: string;
  accountType: string;
  routingNumber: string;
  accountNumber: string;
  current_subscription: Partial<UserData>;
  plansandPricing: PlanAndPricing[];
  getData:ProductData;
  faqsData:any;
  city: string;
  state: string;
  fullName:string;
  address:string;
  zipCode:string;
  anchorEl: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SubscriptionbillingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createSubscriptionApiCallId: any;
  createSubscriptionAchApiCallId: any;
  getSubscriptionApiCallId: any;
  getBankSubscriptionApiCallId: any;
  getAllSubscriptionApiCallId:any;
  getPlansDetailsApiCallId:any;
  getSubscriptionBillingFaqApiCallId:any;
  billingAddressApiCallId:string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      checked: false,
      expanded: null,
      modalOpen: false,
      paymentMethod: 'creditCard',
      showPaymentMethod: '1',
      cardBrand: 'visa',
      cardHolderName: '',
      stripeToken: '',
      cardType: '',
      accountHolderName: '',
      bankName: '',
      accountType: '',
      routingNumber: '',
      accountNumber: '',
      current_subscription: {},
      plansandPricing: [],
      getData:{id: 0,
        name: "",
        details: "",
        storage: 0,
        price: "",
        storage_unit: "",
        stripe_product_id: "",
        stripe_price_id: "",
        created_at: "",
        updated_at: ""},
      faqsData:[],
      city: "",
      state: "",
      fullName:"",
      address:"",
      zipCode:"",
      anchorEl: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let receivedMessage = message.getData(getName(MessageEnum.SessionResponseData));    
      console.log("response 301",receivedMessage);
      
      sessionStorage.setItem("cardType",receivedMessage.cardType)
      this.getPlansDetails(receivedMessage.cardType);
      this.setState({cardType: receivedMessage.cardType}) 
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      if (apiRequestCallId === this.createSubscriptionAchApiCallId) {
      }

      if (responseJson && !responseJson.errors) {

        if (apiRequestCallId === this.createSubscriptionApiCallId) {
          this.handleSubsCard(responseJson);

        }
        else if (apiRequestCallId === this.createSubscriptionAchApiCallId) {
         this.handleSubsAch(responseJson);
        }

        else if (apiRequestCallId === this.getBankSubscriptionApiCallId) {
          this.setState({ current_subscription: responseJson });

        }
        else if(apiRequestCallId === this.billingAddressApiCallId){

          this.handleShowPayment();
          }
        if(apiRequestCallId === this.getAllSubscriptionApiCallId){
          this.setState({plansandPricing: responseJson})
          
        }
        if(apiRequestCallId === this.getPlansDetailsApiCallId){
          this.setState({getData:responseJson});
          console.log("Response 342",responseJson);
          
          
        }

        if(apiRequestCallId === this.getSubscriptionBillingFaqApiCallId){
          this.setState({faqsData:responseJson})
          
        }
        
        

      } 
    }

    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start



  handleAccordion = (index:any) => {
    this.setState((prevState) => ({
      expanded: prevState.expanded === index ? null : index,
    }));
  };

  handleOpenModal = (data: any) => { 
    console.log("response 426",data);
    
    this.setState({cardType:data.name})
    console.log("response 429",data.name);
    
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'PaymentOptions');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.SessionResponseMessage));
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {cardType:data.name });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);



  };

  handleCloseModal = () => {
    this.props.navigation.navigate("LandingPage")
  };
  handleTryAgain = () => {
    this.props.navigation.navigate("PaymentOptions")
  }

  handleToggleButton = (
    event: React.MouseEvent<HTMLElement>,
    newPaymentMethod: 'creditCard' | 'bankTransfer'
  ) => {
    if (newPaymentMethod !== null) {
      this.setState({ paymentMethod: newPaymentMethod });
    }
  };

  handleBankName = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const { value } = event.target || { value: undefined };
    this.setState({ bankName: value as string || '' });
  };

  handleAccountType = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const { value } = event.target || { value: undefined };
    this.setState({ accountType: value as string });
  }


  handleCardNumberChange = (event: any) => {
    this.setState({ cardBrand: event.brand });
  };

  handleCardHolderName = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ cardHolderName: event.target.value });
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
    const { name, value } = event.target;
    if (name) {
      this.setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };



  createSubscriptionApiCall = (id: any) => {
    let token = localStorage.getItem("token");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };

    const body = {
      "plan": sessionStorage.getItem("cardType"),
      "token_stripe": id

    }
    

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createSubscriptionApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createSubscriptionEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createSubscriptionApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createSubscriptionAchApiCall = () => {

    let token = localStorage.getItem("token");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };

    const body = {
      "account_holder_name": this.state.accountHolderName,
      "account_holder_type": this.state.accountType,
      "routing_number": this.state.routingNumber,
      "account_number": this.state.accountNumber,
      "bank_name": this.state.bankName,
      "plan": sessionStorage.getItem("cardType"),
    }



    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createSubscriptionAchApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createSubscriptionAchEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createSubscriptionAchApiMethodType
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  getBankSubscriptionApiCall = () => {

    let token = localStorage.getItem("token");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBankSubscriptionApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBankSubscriptionEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  handleStarted = () => {
    this.props.navigation.navigate('SharingDashboard');
  }

  profileClickButton = () => {
    this.props.navigation.navigate("CustomisableEditprofile");
  }

  subscriptionClickButton = () => {
    this.props.navigation.navigate("Subscription");
  }

  paymentClickBotton = () => {
    this.props.navigation.navigate("PaymentSettings");
  }

  goToPaymentScreen() {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'PaymentSuccesfull');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), { isFrom: "newUser" });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

    SubscriptionBillingAchSchema = Yup.object().shape({
    accountHolderName: Yup.string()
      .required('Full name is required'),
    bankName: Yup.string()
      .required('Bank name is required'),
    accountNumber: Yup.string()
      .required('Account number is required'),
    accountType: Yup.string()
      .required('Account type is required'),
    routingNumber: Yup.string()
      .required('Routing number is required'),
  });

  
  handleSubsCard = (responseJson:any) =>{
    if (responseJson.error) {
      setTimeout(() => {
        this.props.navigation.navigate('PaymentFailed');

      }, 8000);
    }
    else {
      setTimeout(() => {
        this.props.navigation.navigate('PaymentSuccesfull');

      }, 8000);
    }
  }

  handleSubsAch = (responseJson:any)=>{
    if (responseJson.error) {
      setTimeout(() => {
        this.props.navigation.navigate('PaymentFailed');
      }, 8000);
    } else {
      setTimeout(() => {
        this.goToPaymentScreen();
      }, 8000);
    }
  }
  
  formatDate = (inputDate: string): string => {
    const date = moment(inputDate, 'YYYY-MM-DD'); // Adjusted format
    if (!date.isValid()) {
      return 'Invalid date';
    }
  
    const day = date.date();
  
    const getOrdinalSuffix = (day: number): string => {
      const j = day % 10;
      const k = day % 100;
      if (j === 1 && k !== 11) {
        return `${day}st`;
      }
      if (j === 2 && k !== 12) {
        return `${day}nd`;
      }
      if (j === 3 && k !== 13) {
        return `${day}rd`;
      }
      return `${day}th`;
    };
  
    const dayWithSuffix = getOrdinalSuffix(day);
    return `${dayWithSuffix} day of every month`;
  };


  getAllPlansAndPricing = () => {
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllSubscriptionApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllSubscriptionEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  getPlansDetails = (cardType:any) => {
    console.log("response 739",cardType);
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPlansDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllPlansEndPoint + "?name="+ cardType
      
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  getSubscriptionBillingFaq = () => {
    
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSubscriptionBillingFaqApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubscriptionBillingFaqEndPoint 
      
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  handleShowPayment = () => {
    this.setState({ showPaymentMethod: "2" });
  };

  BillingAddressSchema = Yup.object().shape({
    fullName: Yup.string()
  .matches(/^[A-Za-z]+(?:\.| [A-Za-z]+)*(?: [A-Za-z]+)*(?: \.[A-Za-z]+)*\.?$/, 'Full name must contain only alphabets')
  .required("Please fill out full Name."),
  address: Yup.string()
  .matches(/^(?!\s*$).+/, "Address cannot be empty or just spaces")
  .max(100, "Address cannot be longer than 100 characters")
  .required("Please fill out address."),
      city: Yup.string()
      .required("Please fill out city."),
    state: Yup.string()
      .required("Please fill out state."),
      zipCode: Yup.string()
      .matches(/^\d{5}$/, 'Zip code must be 5 digits')
      .required("Please fill out zip Code.")
  });

  billingAddressApi = (values:any) => {

    let token = localStorage.getItem("token");

    const formdata = new FormData();
    formdata.append('full_name', values.fullName);
    formdata.append('address', values.address);
    formdata.append('city', values.city);
    formdata.append('state', values.state);
    formdata.append('zip_code', values.zipCode);


   console.log("response 226",formdata);
   

    const header = {
      token: token
    };

    this.setState({
      fullName: values.fullName,
      address: values.address,
      city: values.city,
      state: values.state,
      zipCode: values.zipCode,
    });


    console.log("response 243", this.state.fullName);
    

    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.billingAddressApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBillingAddressEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getBillingAddressApiMethodType
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  handlePopoverOpen = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null });
  };

  handleCityChange = (event: any) => {
    this.setState({ city: event.target.value });
  };

  handleStateChange = (event: any) => {
    this.setState({ state: event.target.value});
  };

   
  
  
  async componentDidMount(): Promise<void> {
    this.getAllPlansAndPricing();
    if (sessionStorage.getItem("cardType")!=null)
    this.getPlansDetails(sessionStorage.getItem("cardType"));
    this.getSubscriptionBillingFaq();
    }

  // Customizable Area End
}
