export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const Logo = require("../assets/image_Logo.png");
export const backImage = require("../assets/backimage_.png");
export const backArrow = require("../assets/backArrow.png");
export const backImageReset = require("../assets/backImage.png");
export const notValidate = require("../assets/cancel.png");
export const validate = require("../assets/check.png");
export const checkImg = require("../assets/checkImg.png");
export const disableChecked = require("../assets/grayChecked.png");
