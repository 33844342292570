Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "blogpostsmanagement";
exports.labelBodyText = "blogpostsmanagement Body";
exports.BlogpostEndPoint = "bx_block_blogpostsmanagement/blogposts";
exports.btnExampleTitle = "CLICK ME";
exports.test="test";
exports.getAllcategoriesEndPoint = "bx_block_content_management/get_categories";
exports.getAllPostsEndPoint = "bx_block_content_management/get_blog_content";
exports.getAllSearchEndPoint =  "bx_block_content_management/search_category";
exports.getSearchBarEndPoint = "bx_block_content_management/search";
exports.getShowBlogContentEndPoint  = "bx_block_content_management/show_blog_post";
// Customizable Area End