import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as React from 'react';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isChecked: boolean;
  activePolicyIndex: number;
  showError: boolean;
  fullName: string;
  email: string;
  password: string;
  termPolicyData: any[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TermsAndConditionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPolicyDataApiId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SignupPropsMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isChecked: localStorage.getItem("agreeTerms") === "true",
      activePolicyIndex: 0,
      showError: false,
      fullName: '',
      email: '',
      password: '',
      termPolicyData: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson) {
      if (apiRequestCallId === this.getPolicyDataApiId) {
        this.setState({ termPolicyData: responseJson });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getTermsdata();
    const agreeTerms = localStorage.getItem('agreeTerms');
    if (agreeTerms === 'true') {
      this.setState({ isChecked: true });
    }
  }

  handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ isChecked: event.target.checked })
  }

  handleSidebarItemClick = (index: number) => {
    this.setState({ activePolicyIndex: index }, () => {
      const mainContentElement = document.getElementById(`policy-${index}`);
      if (mainContentElement) {
        mainContentElement.scrollIntoView({ behavior: "smooth" });
      }
    });
  };

  handleAgreement = () => {
    if (!this.state.isChecked) {
      this.setState({ showError: true });
    } else {
      localStorage.setItem('agreeTerms', 'true');
      this.props.navigation.navigate("Signuplogin");
    }
  };

  handleCancle = () => {
    this.handleLessonNavigation();
  }

  loginClick = () => {
    this.props.navigation.navigate("Login")
  }

  registerClick = () => {
    this.props.navigation.navigate("Signuplogin")
  }

  handleLessonNavigation = () => {
    const addMessage: Message = new Message(getName(MessageEnum.NavigationSignupMessage));
    addMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(addMessage);
  }

  getTermsdata = () => {
    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getPolicyDataApiId = requestMsg.messageId;
    requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_privacy_setting/privacy_policies`);
    requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMsg.id, requestMsg);
    return true
  }
  // Customizable Area End
}