export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const logo2 = require("../assets/logo.png");
export const avatar2 = require("../assets/avatar.png");
export const subscriptionLogo = require("../assets/subscriptionlogo.png");
export const peopleGroup = require("../assets/peopleGroup.png")
export const frGroup = require("../assets/frGroup.png")
export const downArrow = require("../assets/downArrow.png")
export const profileIcon = require("../assets/profileIcon.png")
export const homeIcon = require("../assets/homeIcon.png")
export const diversity = require("../assets/diversity.png")
export const circleImage = require("../assets/circleImage.png")
export const subscriptionBackground = require("../assets/subscriptionBackground.png")
export const logo = require("../assets/logo2.png");
export const avatar = require("../assets/avatar_.png");
export const BackArrowBlack = require("../assets/arrowBlack.png");
export const download = require("../assets/download.png");
export const check =require("../assets/check.png");
export const visaLogo = require("../assets/visaLogo.svg");
export const wallet = require("../assets/wallet.png");
export const profile = require("../assets/profile.png");
export const dollarSign = require("../assets/dollar.png");
export const logout = require("../assets/image_move_item.png");
export const subscription = require("../assets/view_(3).png");
export const Visa = require("../assets/visa.png");
export const masterCard = require("../assets/masterCard.png");
export const successPayment = require("../assets/successPayment.png");
export const outlineCheck =require("../assets/outlineCheck.png");
export const apartment = require("../assets/apartment.png");
export const backUp = require("../assets/backup_table.png");
export const cloud = require("../assets/cloud_done.png");
export const partner = require("../assets/image_partner_exchange.png");
export const lock = require("../assets/lock.png");
export const sad = require("../assets/sad.png");
export const familyImg = require("../assets/fam.png");
export const group = require("../assets/group.png");
export const home = require("../assets/family_home.png");
export const publish = require("../assets/publish.png")
export const deleteIcon = require("../assets/delete.png");
export const trashIcon = require("../assets/trash.png");
export const failPayment = require("../assets/failPayment.png");
export const creditCardWhiteLogo = require("../assets/creditCardLogo.png");
export const bankAccountWhiteLogo = require("../assets/bankAccountWhiteLogo.png");
export const creditCardGreyLogo = require("../assets/creditCardGreyLogo.png");
export const bankAccountGreyLogo = require("../assets/bankAccountGreyLogo.png");
export const downIcon = require("../assets/downIcon.png");
export const iIcon = require("../assets/iIcon.png");
export const checkImg = require("../assets/checkImg.png")
