import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { navigateTo } from "../../utilities/src/CustomNavigation";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SiteMapController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllSubscriptionApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      subscriptionData: []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  onLoginClick = () => {
    navigateTo({ props: this.props, screenName: 'Login' });
  }

  onRegisterClick = () => {
    navigateTo({ props: this.props, screenName: "Signuplogin" });
  }

  backBtn = () => {
    navigateTo({ props: this.props, screenName: "LandingPage" });
  }

  handleHomeClick = () => {
    navigateTo({ props: this.props, screenName: "LandingPage" });
  }

  handlePlanClick = () => {
    navigateTo({ props: this.props, screenName: "Subscriptionbilling" });
  }

  handleContactusClick = () => {
    navigateTo({ props: this.props, screenName: "ContactusPage" });
  }

  handlePrivacyClick = () => {
    navigateTo({ props: this.props, screenName: "TermsAndConditions" });
  }

  handleBlogClick = () => {
    navigateTo({ props: this.props, screenName: "BlogpostsManagement" });
  }

  handleNewsLetterClick = () => {
    navigateTo({ props: this.props, screenName: "NewsLetter" });
  }

  // Customizable Area End
}
