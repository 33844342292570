import React from "react";

import {
  Box,
  Button,
  Typography,
  TextField,
  TextareaAutosize,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/styles";
import Header from "../../../components/src/Header.web";
// Customizable Area End

import ContactusPageController, {
  Props,
} from "./ContactusPageController";
import { ErrorMessage, Formik } from "formik";
import PhoneInput from "react-phone-input-2";
import Footer from "../../../components/src/Footer.web";
import { bgImage } from "./assets";

// Customizable Area Start
// Customizable Area End

export default class ContactusPage extends ContactusPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ContactusPageStyle>
        <Header data-test-id="header" onLoginClick={this.onLoginClick} onRegisterClick={this.onRegisterClick} getHeaderData={()=>{}}/>
        <Box className="mainContainerBox">
          <Box className="formBox">
            <Typography className="mainTitle">Contact us</Typography>
            <Typography className="subTitle">Fill in the form below, and we will get back to you as soon as possible!</Typography>
            <Formik
              data-test-id="formikContactForm"
              enableReinitialize
              initialValues={{
                name: this.state.name,
                email: this.state.email,
                contactNumber: this.state.contactNumber,
                subject: this.state.subject,
                details: this.state.details,
              }}
              validationSchema={this.contactDetailSchema}
              onSubmit={values => {
                this.submitContactDetails(values);
              }}
            >
              {({ handleSubmit, handleChange, values, setFieldValue }) => (
                <form className="formContainer" onSubmit={handleSubmit}>
                  <Box>
                    <Box>
                      <Typography className="textFieldTitles">Name</Typography>
                      <TextField
                        value={values.name}
                        onChange={handleChange}
                        data-test-id="nameField"
                        type="text"
                        className="placeholderTexts"
                        autoComplete="off"
                        name="name"
                        placeholder="Your name"
                        variant="outlined"
                        fullWidth />
                      <ErrorMessage name="name" component="div" className="errorMessage" />
                      <Typography className="textFieldTitles">E-mail</Typography>
                      <TextField
                        value={values.email}
                        onChange={handleChange}
                        data-test-id="emailField"
                        type="text"
                        className="placeholderTexts"
                        autoComplete="off"
                        name="email"
                        placeholder="Your e-mail address"
                        variant="outlined"
                        fullWidth />
                      <ErrorMessage name="email" component="div" className="errorEmail" />
                      <Typography className="textFieldTitles">Contact information</Typography>
                      <PhoneInput
                        inputProps={{ name: "contactNumber" }}
                        data-test-id="contactNumber"
                        inputStyle={{ width: '100%', height: '7%' }}
                        country={"us"}
                        value={values.contactNumber}
                        onChange={(value: any) => setFieldValue("contactNumber", value)} />
                      <ErrorMessage name="contactNumber" component="div" className="errorContactMessage" />
                      <Typography className="textFieldTitles" style={{ marginTop: '15px' }}>Subject</Typography>
                      <TextField
                        value={values.subject}
                        onChange={handleChange}
                        data-test-id="subject"
                        type="text"
                        className="placeholderTexts"
                        autoComplete="off"
                        name="subject"
                        placeholder="What do you want to talk about?"
                        variant="outlined"
                        fullWidth />
                      <ErrorMessage name="subject" component="div" className="errorMessage" />
                      <Typography className="textFieldTitles">Details</Typography>
                      <TextareaAutosize
                        style={{ height: '56px', width: '100%' }}
                        value={values.details}
                        onChange={handleChange}
                        data-test-id="detailsField"
                        className="placeholderTexts"
                        autoComplete="off"
                        name="details"
                        placeholder="This can be a multiline placeholder"
                        maxLength={500}
                      />
                      <ErrorMessage name="details" component="div" className="errorMessage" />
                      <div className="coutingDiv">
                        <span className="countingNum">
                          {values.details.length}/{500}
                        </span>
                      </div>
                      <Box className="btnsDiv">
                        <Button className="saveChangesBtn" type="submit" data-test-id="saveChangesBtn">
                          Send message
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
        <Footer />
      </ContactusPageStyle>
      // Customizable Area End
    );
  }
}

const ContactusPageStyle = styled("div")({
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  "& .mainContainerBox": {
    display: "flex",
    flexDirection: 'row-reverse',
    background: `url(${bgImage}) no-repeat`,
  },
  "& .formBox": {
    padding: "30px",
    marginTop: '40px',
    marginBottom: '20px',
    backgroundColor: 'white',
    marginRight: '15%'
  },
  "& .formContainer": {
    marginBottom: '60px'
  },
  "& .mainTitle": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "30px",
    lineHeight: "40px",
    letterSpacing: '-0.5%',
    color: '#1C2110',
  },
  "& .subTitle": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: '#1C2110',
    marginTop: '10px',
    marginBottom: '25px'
  },
  "& .placeholderTexts": {
    marginBottom: '15px',
    marginTop: '5px'
  },
  "& .coutingDiv": {
    display: "flex",
    flexDirection: 'row-reverse',
  },
  "& .countingNum": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    color: '#44403C',
  },
  "& .placeholderTexts::placeholder": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: 'currentColor',
    padding: '5px'
  },
  "& .textFieldTitles": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: '#1C2110',
    marginTop: '15px'
  },
  "& .errorMessage": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    color: '#DC2626',
    marginTop: '-12px'
  },
  "& .errorContactMessage": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    color: '#DC2626',
    marginTop: '2px'
  },
  "& .errorEmail": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    color: '#DC2626',
    marginTop: '-10px',
    marginBottom: '15px',
  },
  "& .saveChangesBtn": {
    backgroundColor: '#8CA74B',
    width: '250px',
    height: '56px',
    marginTop: '50px',
    borderRadius: '8px',
    '& .MuiButton-label': {
      fontSize: "16px",
      fontWeight: 700,
      textTransform: "none",
      fontFamily: "Poppins",
      lineHeight: "24px",
      color: 'white'
    }
  },
});
// Customizable Area End