import React from "react";

import {
    Box,
    Button,
    Typography,
    IconButton,
    TextField,
    FormControl,
    Select,
    MenuItem,
    Radio,
    // Customizable Area Start
    RadioProps,
    Card,
    CardContent,
    Checkbox,
    Container,
    FormControlLabel,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Divider,
    Popover,
    InputAdornment,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled, withStyles } from "@material-ui/core/styles";
import { BackArrowBlack, iIcon, profile, failPayment } from "./assets";
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import CircleChecked from '@material-ui/icons/CheckCircleOutline';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CloseIcon from '@mui/icons-material/Close';
import HeaderDropdown from "../../../components/src/HeaderDropdown.web";

// Customizable Area End

import PaymentController, {
    Props
} from "./PaymentController.web";


// Customizable Area Start
const GreenRadio = withStyles({
    root: {
        color: '78716C',
        '&$checked': {
            color: '#557C29',
        },
    },
    checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
    color: '#557C29',
    '&.Mui-checked': {
        color: '#557C29',
    },
    borderRadius: '4px',
}));

// Customizable Area End

export default class PaymentCheckout extends PaymentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <Box style={{ backgroundColor: '#FEFFFB' }}>
                <HeaderDropdown data-test-id="headerComponent" editProfileClick={this.profileClickButton} subscriptionClick={this.subscriptionClickButton} paymentClick={this.paymentClickBotton} />
                <Box style={{ padding: '30px', marginRight: '20px', marginLeft: '20px', }}>
                    <Box style={{ display: 'flex' }}>
                        <Button className="backButton" >
                            <img style={{ color: 'black' }} src={BackArrowBlack} alt="Back" />
                        </Button>
                        <Typography style={webStyle.checkoutText}>Checkout</Typography>
                    </Box>

                </Box>
                <Box style={{ display: 'flex', justifyContent: 'center', marginBottom: 60 }}>
                    <Box style={{ display: 'flex', justifyContent: 'center' }}>
                        <Card
                            style={{
                                width: "519px",
                                height: "277px",
                                borderBottomRightRadius: "40px",
                                boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.16)",
                                padding: "16px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                            }}
                        >
                            <CardContent>
                                <Typography
                                    style={{ fontSize: 20, fontWeight: 700, fontFamily: "Poppins" }}
                                >
                                    Plan & Pricing
                                </Typography>
                                <Box
                                    sx={{
                                        border: "1px solid #E0E0E0",
                                        borderRadius: "8px",
                                        padding: "16px",
                                        marginTop: 10,
                                    }}
                                >
                                    <Typography
                                        style={{ fontSize: 18, fontWeight: 700, color: '#000' }}
                                        gutterBottom
                                    >
                                        Individuals
                                    </Typography>
                                    <Typography style={{ marginBottom: 1.5, color: '#78716C' }} >
                                        The optimal plan for individual projects
                                    </Typography>
                                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>

                                        <Checkbox
                                            checked={this.state.checked}
                                            data-test-id="checkBoxBtn"
                                            onChange={this.handleChange}
                                            icon={<CircleUnchecked />}
                                            checkedIcon={this.state.checked ? <CircleCheckedFilled style={{ color: '#8CA74B' }} /> : <CircleChecked style={{ color: '#8CA74B' }} />}
                                        />
                                        <Typography style={{ color: '#78716C' }}>
                                            100 GB of storage
                                        </Typography>
                                    </Box>

                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "baseline",
                                        }}
                                    >
                                        <Typography
                                            style={{ fontSize: 32, fontWeight: 700, color: '#000' }}
                                        >
                                            $3.99
                                        </Typography>
                                        <Typography
                                            style={{ fontSize: 16, fontWeight: 400, marginLeft: 1, color: '#78716C' }}
                                        >
                                            / month
                                        </Typography>
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                    {this.state.showPaymentMethod == "1" &&
                    <Box>
                        <Card style={{ width: "494px", marginLeft: 50 }}>
                            <CardContent>
                                <Typography
                                    style={{
                                        fontSize: 20,
                                        fontWeight: 700,
                                        fontFamily: "Poppins",
                                        color: "#1C2110",
                                    }}
                                >
                                    Billing Address
                                </Typography>
                                <Typography
                                    style={{
                                        fontSize: 16,
                                        fontWeight: 400,
                                        fontFamily: "Poppins",
                                        color: "#5D6063",
                                    }}
                                >
                                    Designated location associated with your financial account, used for
                                    invoicing and payment purposes.
                                </Typography>
                                <Box display="flex" alignItems="center" flexDirection='row'>
                                    <img
                                        src={iIcon}
                                        style={{ width: 20, height: 20, marginTop: 10, cursor: 'pointer' }}
                                        onClick={this.handlePopoverOpen}
                                        data-test-id="modelopenPop"
                                        alt="icon"
                                    />
                                    <Popover
                                        open={this.state.anchorEl}
                                        anchorEl={this.state.anchorEl}
                                        onClose={this.handlePopoverClose}
                                        data-test-id="modelclosePop"
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: -10,
                                        }}
                                        disableRestoreFocus
                                    >
                                        <Box
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                padding: '2px 8px',
                                                borderLeft: '5px solid #8CA74B',
                                                backgroundColor: "#f7f0f0",
                                                fontFamily: 'Arial, sans-serif',
                                                fontSize: '16px',
                                                borderRadius: '5px',
                                                maxWidth: '400px',
                                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                            }}
                                        >
                                            <Typography variant="body1" id="custom-dialog-description" style={{ fontSize: 12, fontWeight: 400, fontFamily: 'Poppins' }}>
                                                Currently we only accept payments of US
                                            </Typography>
                                            <IconButton style={{ marginLeft: 'auto' }} onClick={this.handlePopoverClose}>
                                                <CloseIcon />
                                            </IconButton>
                                        </Box>
                                    </Popover>
                                </Box>

                                <Box style={{ marginTop: 50 }}>

                                    <Typography style={webStyle.titleTexts}>Full Name</Typography>
                                    <TextField
                                        data-test-id="fullNameField"
                                        type="text"

                                        style={{ marginBottom: '20px' }}
                                        autoComplete="off"
                                        fullWidth
                                        name="fullName"
                                        placeholder="Enter Your Full Name"
                                        variant="outlined"
                                    />
                                    <Typography style={webStyle.titleTexts}>Address</Typography>
                                    <TextField
                                        data-test-id="addressField"
                                        type="text"

                                        style={{ marginBottom: '20px' }}
                                        autoComplete="off"
                                        fullWidth
                                        name="Address"
                                        placeholder="Enter Your Address"
                                        variant="outlined"
                                    />
                                    <Typography style={webStyle.titleTexts}>City</Typography>
                                    <FormControl variant="outlined" style={{ marginBottom: '20px' }} fullWidth>
                                        <Select
                                            value={this.state.city}
                                            onChange={this.handleCityChange}
                                            displayEmpty
                                            renderValue={this.state.city !== "" ? undefined : () => "Select city"}
                                        >
                                            <MenuItem value="">
                                                <span>Select city</span>
                                            </MenuItem>
                                            <MenuItem value="Washington, D.C.">Washington, D.C.</MenuItem>
                                            <MenuItem value="Palmer">Palmer</MenuItem>
                                            <MenuItem value="Montrose">Montrose</MenuItem>
                                            <MenuItem value="Pensacola">Pensacola</MenuItem>
                                            <MenuItem value="Chicago">Chicago</MenuItem>
                                            <MenuItem value="Columbus">Columbus</MenuItem>
                                            <MenuItem value="Indianapolis">Indianapolis</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Box style={{ width: '230px' }}>
                                            <Typography style={webStyle.titleTexts}> State</Typography>
                                            <FormControl variant="outlined" style={{ marginBottom: '20px' }} fullWidth>
                                                <Select
                                                    value={this.state.state}
                                                    onChange={this.handleStateChange}
                                                    displayEmpty
                                                    renderValue={this.state.state !== "" ? undefined : () => "Select state"}
                                                    data-test-id="selectState"
                                                >
                                                    <MenuItem value="">
                                                        <span>Select state</span>
                                                    </MenuItem>
                                                    <MenuItem value="Washington">Washington</MenuItem>
                                                    <MenuItem value="Texas">Texas</MenuItem>
                                                    <MenuItem value="California">California</MenuItem>
                                                    <MenuItem value="Florida">Florida</MenuItem>
                                                    <MenuItem value="New Jersey">New Jersey</MenuItem>
                                                    <MenuItem value="Indiana">Indiana</MenuItem>
                                                    <MenuItem value="Alaska">Alaska</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        <Box style={{ marginLeft: 20 }}>
                                            <Typography style={webStyle.titleTexts}>Zip code</Typography>
                                            <TextField
                                                data-test-id="zipCodeField"

                                                style={{ marginBottom: '20px' }}
                                                autoComplete="off"
                                                name="zipCode"
                                                placeholder="Enter Your Zip code"
                                                variant="outlined"
                                            />
                                        </Box>
                                    </Box>
                                    <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button style={webStyle.continueButton} onClick={this.handleContinue} data-test-id="ContinueButtonTest">
                                            <Typography style={webStyle.continueText as any}>Continue</Typography>
                                        </Button>
                                    </Box>

                                </Box>
                            </CardContent>
                        </Card>
                    </Box>}
                    {(this.state.showPaymentMethod == "2") && (
                        <Box style={{ display: 'flex', justifyContent: 'center', marginBottom: 100 }}>
                            <Box style={{ width: "519px", marginLeft: 50 }}>
                                <Card
                                    style={{
                                        width: "519px",
                                        // height: "277px",
                                        borderBottomRightRadius: "40px",
                                        boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.16)",
                                        padding: "16px",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <CardContent>

                                        <Container>
                                            <Typography style={{ fontSize: 20, fontWeight: 700, fontFamily: 'Poppins', color: '#1C2110' }}>Payment method</Typography>
                                            <Typography style={{ fontSize: 16, fontWeight: 400, fontFamily: 'Poppins', marginTop: 5, color: '#5D6063' }}>You can opt for either ACH (Automated Clearing House) or credit card as your payment method.</Typography>

                                            <Box style={{
                                                marginTop: 20,
                                                backgroundColor: "#f7f0f0",
                                                width: "100%",
                                                minWidth: 408,
                                                padding: 2,
                                                borderRadius: 40
                                            }}>
                                                <ToggleButtonGroup
                                                    value={this.state.paymentMethod}
                                                    exclusive
                                                    onChange={this.handleToggleButton}
                                                    aria-label="payment method"
                                                    fullWidth
                                                    data-test-id="toggleButton"

                                                >
                                                    <ToggleButton
                                                        value="bankTransfer"
                                                        aria-label="bank transfer"
                                                        style={{
                                                            fontWeight: 700,
                                                            fontSize: 16,
                                                            fontFamily: 'Poppins',
                                                            // color: '#78716C',
                                                            textTransform: 'none',
                                                            borderColor: '#f7f0f0',
                                                            borderRadius: 30,
                                                            color: this.state.paymentMethod === 'bankTransfer' ? '#FAFAF9' : '#78716C',
                                                            backgroundColor: this.state.paymentMethod === 'bankTransfer' ? '#8CA74B' : '#f7f0f0'
                                                        }}
                                                    >
                                                        Bank transfer (ACH)
                                                    </ToggleButton>
                                                    <ToggleButton value="creditCard" aria-label="credit card" style={{
                                                        fontWeight: 700,
                                                        fontSize: 16,
                                                        fontFamily: 'Poppins',
                                                        // color: '#fff',
                                                        textTransform: 'none',
                                                        borderRadius: 30,
                                                        borderColor: '#f7f0f0',
                                                        color: this.state.paymentMethod === 'creditCard' ? '#ffffff' : '#78716C',
                                                        backgroundColor: this.state.paymentMethod === 'creditCard' ? '#8CA74B' : '#f7f0f0'
                                                    }}>
                                                        Credit Card
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                            </Box>

                                            {this.state.paymentMethod === 'creditCard' && (
                                                <div style={{ marginTop: 30 }}>
                                                    <Typography style={webStyle.titleTexts}>Cardholder name</Typography>
                                                    <TextField
                                                        data-test-id="fullNameField"
                                                        type="text"
                                                        style={{ marginBottom: '20px', marginTop: 5 }}
                                                        autoComplete="off"
                                                        fullWidth
                                                        name="fullName"
                                                        placeholder="Enter Your Full Name"
                                                        variant="outlined"
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <img
                                                                        src={profile}
                                                                        style={{ width: 24, height: 24 }}
                                                                    />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />

                                                    <Typography style={webStyle.titleTexts}>Card number</Typography>
                                                    <TextField
                                                        data-test-id="fullNameField"
                                                        type="text"
                                                        style={{ marginBottom: '20px', marginTop: 5 }}
                                                        autoComplete="off"
                                                        fullWidth
                                                        name="fullName"
                                                        placeholder="Enter Your Full Name"
                                                        variant="outlined"
                                                    />
                                                    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                        <div style={{ flex: '1', marginRight: '10px' }}>
                                                            <Typography style={webStyle.titleTexts}>Expiration date</Typography>
                                                            <TextField
                                                                data-test-id="expirationDateField"
                                                                type="text"
                                                                style={{ marginBottom: '20px', marginTop: 5 }}
                                                                autoComplete="off"
                                                                fullWidth
                                                                name="expirationDate"
                                                                placeholder="MM/YY"
                                                                variant="outlined"
                                                            />
                                                        </div>
                                                        <div style={{ flex: '1', marginLeft: '10px' }}>
                                                            <Typography style={webStyle.titleTexts}>Security code</Typography>
                                                            <TextField
                                                                data-test-id="securityCodeField"
                                                                type="text"
                                                                style={{ marginBottom: '20px', marginTop: 5 }}
                                                                autoComplete="off"
                                                                fullWidth
                                                                name="securityCode"
                                                                placeholder="Enter security code"
                                                                variant="outlined"
                                                            />
                                                        </div>
                                                    </Box>

                                                    <FormControlLabel
                                                        control={<CustomCheckbox name="saveCard" />}
                                                        label={
                                                            <Typography
                                                                style={{
                                                                    fontSize: '14px',
                                                                    fontWeight: 400,
                                                                    fontFamily: 'Poppins',
                                                                    color: '#1C2110'
                                                                }}
                                                            >
                                                                Save this payment method for future transactions.
                                                            </Typography>
                                                        }
                                                    />
                                                </div>
                                            )}

                                            {this.state.paymentMethod === 'bankTransfer' && (
                                                <div style={{ marginTop: 30 }}>
                                                    <Typography style={webStyle.titleTexts}>Full Name</Typography>
                                                    <TextField
                                                        data-test-id="fullNameField"
                                                        type="text"
                                                        style={{ marginBottom: '20px', marginTop: 5 }}
                                                        autoComplete="off"
                                                        fullWidth
                                                        name="fullName"
                                                        placeholder="Enter Your Full Name"
                                                        variant="outlined"
                                                    />
                                                    <div>
                                                        <Typography style={webStyle.titleTexts}>Bank name</Typography>
                                                        <FormControl variant="outlined" fullWidth margin="normal">
                                                            <Select
                                                                data-test-id="bankfield"
                                                                value={this.state.bankName}
                                                                onChange={this.handleBankName}
                                                                displayEmpty
                                                                renderValue={this.state.bankName !== "" ? undefined : () => "Select bank name"}
                                                                name="bankName"
                                                            >
                                                                <MenuItem value="">
                                                                    <span>Select bank name</span>
                                                                </MenuItem>
                                                                <MenuItem value="Bank of America">Bank of America</MenuItem>
                                                                <MenuItem value="Chase">Chase</MenuItem>
                                                                <MenuItem value="Wells Fargo">Wells Fargo</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                        <div style={{ flex: '1', marginRight: '10px' }}>
                                                            <Typography style={webStyle.titleTexts}>Account number</Typography>
                                                            <TextField
                                                                data-test-id="expirationDateField"
                                                                type="text"
                                                                style={{ marginBottom: '20px', marginTop: 5 }}
                                                                autoComplete="off"
                                                                fullWidth
                                                                name="expirationDate"
                                                                placeholder="MM/YY"
                                                                variant="outlined"
                                                            />
                                                        </div>
                                                        <div style={{ flex: '1', marginLeft: '10px' }}>
                                                            <Typography style={webStyle.titleTexts}>Bank account type</Typography>
                                                            <FormControl style={{ marginBottom: '20px', marginTop: 5 }} fullWidth>
                                                                <Select
                                                                    data-test-id="expirationDateField"
                                                                    value={this.state.accountType}
                                                                    onChange={this.handleAccountType}
                                                                    variant="outlined"
                                                                    autoComplete="off"
                                                                    fullWidth
                                                                    displayEmpty
                                                                    renderValue={this.state.accountType !== "" ? undefined : () => "Select account type"}
                                                                >
                                                                    <MenuItem value="">
                                                                        <span>Select account type</span>
                                                                    </MenuItem>
                                                                    <MenuItem value="current">Current</MenuItem>
                                                                    <MenuItem value="saving">Saving</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                    </Box>

                                                    <Typography style={webStyle.titleTexts}>Routing number</Typography>
                                                    <TextField
                                                        data-test-id="fullNameField"
                                                        type="text"
                                                        style={{ marginBottom: '20px', marginTop: 5 }}
                                                        autoComplete="off"
                                                        fullWidth
                                                        name="fullName"
                                                        placeholder="Enter Your Full Name"
                                                        variant="outlined"
                                                    />
                                                    <FormControlLabel
                                                        control={<CustomCheckbox name="saveCard" />}
                                                        label={
                                                            <Typography
                                                                style={{
                                                                    fontSize: '14px',
                                                                    fontWeight: 400,
                                                                    fontFamily: 'Poppins',
                                                                    color: '#1C2110'
                                                                }}
                                                            >
                                                                Save this payment method for future transactions.
                                                            </Typography>
                                                        }
                                                    />
                                                </div>
                                            )}

                                            <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <Button style={webStyle.continueButton} onClick={this.handleOpenModal} data-test-id="continueButton">
                                                    <Typography style={webStyle.continueText as any}>Proceed to pay</Typography>
                                                </Button>
                                            </Box>
                                        </Container>
                                    </CardContent>
                                </Card>

                            </Box>
                            <Box>
                                <Dialog open={this.state.isPaymentModelOpen} onClose={this.handleCloseModal} data-test-id="dilogcloseButton" PaperProps={{
                                    style: {
                                        height: 555,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        borderRadius: 8,
                                        borderBottomRightRadius: 50,
                                    },
                                }} >
                                    <DialogTitle style={{ fontSize: 20, fontWeight: 700, fontFamily: 'Poppins' }}>
                                        <Typography style={{ fontSize: 20, fontWeight: 700, fontFamily: 'Poppins', color: '#1C2110' }}>Failed payment</Typography>
                                        <IconButton
                                            aria-label="close"
                                            onClick={this.handleCloseModal}
                                            style={{
                                                position: 'absolute',
                                                right: 8,
                                                top: 8,
                                                color: '#666',
                                            }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </DialogTitle>
                                    <Divider />
                                    <DialogContent style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                        <div style={{
                                            width: "130px",
                                            height: "130px",
                                            borderRadius: "50%",
                                            backgroundColor: "#F87171",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            alignSelf:'center'
                                        }}>
                                            <img
                                                src={failPayment}
                                                alt="Centered"
                                                className="image"
                                                style={{width:85}}
                                            />
                                        </div>
                                        <DialogContentText style={{ textAlign: 'center', marginTop: 16 }}>
                                            <Typography style={{ fontSize: 20, fontWeight: 700, fontFamily: 'Poppins', color: '#1C2110' }}>Failed to process your payment</Typography>
                                        </DialogContentText>
                                        <DialogContentText style={{ textAlign: 'center', marginTop: 8, fontFamily: 'Poppins', fontWeight: 400, fontSize: 16, color: '#1C2110' }}>
                                            We were unable to process your payment. Please double-check your payment information and ensure that the details provided are accurate.
                                        </DialogContentText>
                                    </DialogContent>
                                    <Divider />
                                    <DialogActions style={{ display: 'flex', justifyContent: 'center', paddingBottom: 30 }}>


                                        <Button style={webStyle.continueButton} onClick={this.handleCloseModal}>
                                            <Typography style={webStyle.continueText as any}>Cancel</Typography>
                                        </Button>

                                        <Button style={webStyle.continueButton} onClick={this.handleCloseModal}>
                                            <Typography style={webStyle.continueText as any}>Try again</Typography>
                                        </Button>

                                    </DialogActions>
                                </Dialog>
                            </Box>
                        </Box>

                    )}
                </Box>


            </Box >
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const webStyle = {
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '20px',
        borderBottom: '1px solid #E7E5E4',
        backgroundColor: '#FEFFFB'
    },
    checkoutText: {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "30px",
        lineHeight: "40px",
        letterSpacing: "-0.5%",
        color: "#1C2110",
    },
    titleTexts: {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#1C2110"
    },
    continueButton: {
        backgroundColor: "#8CA74B",
        borderRadius: '8px',
        height: '56px',
        width: '195px',
        marginTop: '32px',
    },
    continueText: {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#FFFFFF",
        textTransform: 'none'
    }
};
// Customizable Area End
