import React from "react";

import {
    Box,
    Button,
    Typography,
    TextField,
    // Customizable Area Start
    InputAdornment,
    IconButton,
    Popover,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/styles";
import CloseIcon from '@material-ui/icons/Close';
import { Logo, backArrow, backImageReset, checkImg, disableChecked, notValidate, validate } from "./assets";
import { Formik } from "formik";
// Customizable Area End

import ResetPasswordController, {
    Props,
} from "./ResetPasswordController.web";

export default class ResetPassword extends ResetPasswordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderValidatePassword = (password:string, confirmPassword:string) => {
        const isPasswordEmpty = password.trim().length === 0;
        const passwordsMatch = password === confirmPassword;
    
        return (
            <div className="validatePasswordWrapper">
                {this.validatePassword.map((item, index) => {
                    const isValid = item.regex.test(password);
                    return (
                        <div
                            key={index}
                            className="validatePasswordItem"
                            style={{
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: '14px',
                                lineHeight: "18px",
                            }}
                        >
                            {isPasswordEmpty ? (
                                <img
                                    src={disableChecked}
                                    alt="checkIcon"
                                    className="check-icon"
                                    style={{ marginRight: "8px", height: "14px", width: "14px" }}
                                />
                            ) : (
                                <img
                                    src={isValid ? validate : notValidate}
                                    alt="checkIcon"
                                    className="check-icon"
                                    style={{ marginRight: "8px", height: "14px", width: "14px" }}
                                />
                            )}
                            {item.text}
                        </div>
                    );
                })}
                {isPasswordEmpty ? (
                    <div
                        className="validatePasswordItem"
                        style={{
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: '14px',
                        }}
                    >
                        <img
                            src={disableChecked}
                            alt="checkIcon"
                            className="check-icon"
                            style={{ marginRight: "8px", height: "14px", width: "14px" }}
                        />
                        Passwords match
                    </div>
                ) : (
                    passwordsMatch ? (
                        <div
                            className="validatePasswordItem"
                            style={{
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: '14px',
                            }}
                        >
                            <img
                                src={validate}
                                alt="checkIcon"
                                className="check-icon"
                                style={{ marginRight: "8px", height: "14px", width: "14px" }}
                            />
                            Passwords match
                        </div>
                    ) : (
                        <div
                            className="validatePasswordItem"
                            style={{
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: '14px',
                            }}
                        >
                            <img
                                src={notValidate}
                                alt="checkIcon"
                                className="check-icon"
                                style={{ marginRight: "8px", height: "14px", width: "14px" }}
                            />
                            Passwords match
                        </div>
                    )
                )}
            </div>
        );
    };
    
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ResetPasswordStyle>
                <Button data-test-id="backArraowBotton" className="backArrowClick" onClick={this.backArraowClick}>
                    <img src={backArrow} alt="Back" />
                </Button>
                <Box className="mainDiv">
                    <Box className="formContainer">
                        <Box className="imageLogo">
                            <img style={{ height: '70px' }} src={Logo} alt="Logo" />
                        </Box>
                        <Formik
                            data-test-id="formikForm"
                            initialValues={{
                                password: this.state.resetPasswordData.password || "",
                                confirmPassword: this.state.resetPasswordData.confirmPassword || "",
                            }}
                            validationSchema={this.resetPasswordSchema}
                            onSubmit={values => {
                                if (this.checkValidatePassword(values.password)) {
                                    this.submitValidateAccount(values.password, values.confirmPassword);
                                }
                            }}
                        >
                            {({ errors, touched, handleSubmit, handleChange, values }) => (
                                <form className="formContainer" onSubmit={handleSubmit}>
                                    <Typography className="mainTitles">New password</Typography>
                                    <Typography className="forgotTextTitle">Enter a new password for a
                                        <br />
                                       {localStorage.getItem("email")}
                                       </Typography>
                                    <Typography className="FieldName">New Password</Typography>
                                    <TextField
                                        data-test-id="passwordField"
                                        className="placeholderTitle"
                                        value={values.password}
                                        onChange={handleChange}
                                        error={
                                            Boolean(touched.password && errors.password) ||
                                            Boolean(this.state.formError.password)
                                        }
                                        helperText={
                                            (touched.password && errors.password) ||
                                            this.state.formError.password
                                        }
                                        autoComplete="off"
                                        fullWidth
                                        name="password"
                                        placeholder="Your new password"
                                        variant="outlined"
                                        type={!this.state.showPassword ? "password" : "text"}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        data-test-id="showPasswordIconButton"
                                                        id="passwordConfirmationInput"
                                                        onClick={() => this.handleClickShowPassword("password")}
                                                        edge="end"
                                                    >
                                                        {this.renderIconShowPassword(!this.state.showPassword)}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <Typography className="FieldName">Confirm new Password</Typography>
                                    <TextField
                                        data-test-id="confirmPasswordField"
                                        className="placeholderTitle"
                                        value={values.confirmPassword}
                                        onChange={handleChange}
                                        error={
                                            Boolean(touched.confirmPassword && errors.confirmPassword) ||
                                            Boolean(this.state.formError.confirmPassword)
                                        }
                                        helperText={
                                            (touched.confirmPassword && errors.confirmPassword) ||
                                            this.state.formError.confirmPassword
                                        }
                                        autoComplete="off"
                                        fullWidth
                                        name="confirmPassword"
                                        placeholder="Confirm your new password"
                                        variant="outlined"
                                        type={!this.state.showConfirmPassword ? "password" : "text"}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        id="passwordConfirmationInput"
                                                        onClick={() => this.handleClickShowPassword("confirmPassword")}
                                                        edge="end"
                                                        data-test-id="showPasswordIconButton"
                                                    >
                                                        {this.renderIconShowConfirmPassword(!this.state.showConfirmPassword)}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    {this.renderValidatePassword(values.password, values.confirmPassword)}
                                    <Button type="submit" data-test-id="recoveryLinkBtn" className="recoveryLinkBtn" variant="contained" color="primary" fullWidth >
                                        Reset password
                                    </Button>

                                    <Box className="accountDiv">
                                        <Typography className="accountTitle">Back to</Typography>
                                        <Button onClick={this.handleNavigateLogin} data-test-id="loginBtn" className="loginBottons">Log in</Button>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Box>
                </Box>
                {!this.state.passwordChanged && (
                    <Popover
                        open={this.state.showModal}
                        onClose={this.handleCloseModel}
                        anchorOrigin={{
                            vertical: "center",
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: "center",
                            horizontal: "center",
                        }}
                    >
                        <PopoverContent>
                            <IconButton
                                data-test-id='closeIcon'
                                className="closeIcon"
                                onClick={this.handleCloseModel}
                            >
                                <CloseIcon />
                            </IconButton>
                            <Box className="mainDiv">
                                <Box className="checkImgBox">
                                    <img className='checkImg' src={checkImg} />
                                </Box>
                                <Typography className="passwordText">Password changed Successfully!!</Typography>
                            </Box>

                        </PopoverContent>
                    </Popover>
                )}
            </ResetPasswordStyle>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const PopoverContent = styled(Box)(() => ({
    width: "350px",
    display: 'flex',
    height: "275px",
    padding: "20px",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    position: 'relative',
    "@media(max-width: 768px)": {
        padding: "10px",
        height: "240px !important",
    },
    "& .mainDiv": {
        display: 'flex',
        flexDirection: "column",
        alignItems: "center",
    },
    "& .checkImgBox": {
        backgroundColor: '#34D399',
        display: 'flex',
        height: '75px',
        width: '75px',
        borderRadius: '45px',
        justifyContent: 'center',
        alignItems: 'center'
    },
    "& .checkImg": {
        height: '45px',
        width: '45px'
    },
    "& .closeIcon": {
        position: 'absolute',
        top: 10,
        right: 10,
        zIndex: 999,
        color: '#44403C'
    },
    "& .passwordText": {
        marginTop: "75px",
        fontFamily: "Poppins",
        fontWeight: 650,
        fontSize: "24px",
        lineHeight: "32px",
        letterSpacing: "-0.5%",
        color: "#1C2110"
    }
}));

const ResetPasswordStyle = styled("div")({
    height: "100vh",
    backgroundImage: `url(${backImageReset})`,
    width: "100%",
    backgroundColor: "#305328",
    backgroundRepeat: "no-repeat",
    justifyContent: 'center',
    display: "flex",
    overflow: "hidden",
    alignItems: "center",
    position: 'relative',

    "& .backArrowClick": {
        top: "20px",
        position: 'fixed',
        zIndex: 9999,
        left: "20px",
    },
    "& .mainDiv": {
        width: "440px",
        borderRadius: "20px",
        padding: "40px",
        overflow: "hidden",
        backgroundColor: "white",
    },
    "& .scrollableContent": {
        maxHeight: "calc(100vh - 160px)",
        overflowY: 'auto',
    },
    "& .formContainer": {
        width: "100%",
    },
    "& .imageLogo": {
        padding: '0px, 0px, 0px, 40px',
    },
    "& .validatePasswordWrapper": {
        marginTop: "12px"
    },
    "& .mainTitles": {
        marginTop: "32px",
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "24px",
        lineHeight: "32px",
        letterSpacing: "-0.5%",
        color: "#1C2110"
    },
    "& .forgotSubTitle": {
        marginTop: '8px',
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#1C2110",
        marginBottom: '16px'
    },
    "& .forgotTextTitle": {
        marginTop: '8px',
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#202020",
        marginBottom: '30px'
    },
    "& .recoveryLinkBtn": {
        color: "#FFFFFF",
        backgroundColor: '#8CA74B',
        borderRadius: "8px",
        height: "56px",
        Gap: "8px",
        padding: "16px",
        fontWeight: 700,
        marginTop: "24px",
        lineHeight: "24px",
        fontSize: "16px",
        '& .MuiButton-label': {
            fontSize: "16px",
            fontWeight: 700,
            textTransform: "none",
            fontFamily: "Poppins",
        }
    },
    "& .accountDiv": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: '16px'
    },
    "& .FieldName": {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#1C2110",
        marginTop: "16px",
        marginBottom: "4px"
    },
    "& .placeholderTitle": {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        fontFamily: "Poppins",
        color: "#94A3B8"
    },
    "& .accountTitle": {
        fontWeight: 400,
        lineHeight: "24px",
        fontSize: "16px",
        color: "#1C2110",
        fontFamily: "Poppins",
    },
    "& .loginBottons": {
        fontStyle: "Poppins",
        fontWeight: 700,
        lineHeight: "24px",
        fontSize: "16px",
        color: "#557C29",
        '& .MuiButton-label': {
            fontFamily: "Poppins",
            fontWeight: 700,
            textTransform: "none"
        }
    },
});
// Customizable Area End