Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.searchApiContentType = "application/json";
exports.getSearchApiEndPoint = "search/users";
exports.errorTitle = "Error";
exports.getCustomizableProfileEndPoint = "bx_block_profile/profile/current_user_profile";
exports.updateCustomizableProfileEndPoint = "bx_block_profile/profile/update_user_profile";
exports.getCustomizableProfileFieldsEndPoint =
  "bx_block_profile/profile/custom_user_profile_fields";
exports.stateCityMap = {
  'Maryland': [
    { value: 'columbia', label: 'Columbia' },
    { value: 'germantown', label: 'Germantown' },
    { value: 'silver_spring', label: 'Silver Spring' },
    { value: 'baltimore', label: 'Baltimore' },
  ],
  'Georgia': [
    { value: 'columbus', label: 'Columbus' },
    { value: 'augusta', label: 'Augusta' },
    { value: 'atlanta', label: 'Atlanta' },
    { value: 'macon', label: 'Macon' },
  ],
  'Massachusetts': [
    { value: 'boston', label: 'Boston' },
    { value: 'lowell', label: 'Lowell' },
    { value: 'springfield', label: 'Springfield' },
    { value: 'worcester', label: 'Worcester' },
  ],
  'Hawaii': [
    { value: 'hilo', label: 'Hilo' },
    { value: 'pearl_city', label: 'Pearl City' },
    { value: 'kailua', label: 'Kailua' },
    { value: 'honolulu', label: 'Honolulu' },
  ],
  'Minnesota': [
    { value: 'minneapolis', label: 'Minneapolis' },
    { value: 'st_paul', label: 'St. Paul' },
    { value: 'duluth', label: 'Duluth' },
    { value: 'rochester', label: 'Rochester' },
  ],
  'Illinois': [
    { value: 'chicago', label: 'Chicago' },
    { value: 'naperville', label: 'Naperville' },
    { value: 'peoria', label: 'Peoria' },
    { value: 'aurora', label: 'Aurora' },
  ],
  'Mississippi': [
    { value: 'hattiesburg', label: 'Hattiesburg' },
    { value: 'southaven', label: 'Southaven' },
    { value: 'jackson', label: 'Jackson' },
    { value: 'gulfport', label: 'Gulfport' },
  ],
  'Michigan': [
    { value: 'warren', label: 'Warren' },
    { value: 'grand_rapids', label: 'Grand Rapids' },
    { value: 'detroit', label: 'Detroit' },
    { value: 'sterling_heights', label: 'Sterling Heights' },
  ],
  'Missouri': [
    { value: 'st_louis', label: 'St. Louis' },
    { value: 'columbia', label: 'Columbia' },
    { value: 'springfield', label: 'Springfield' },
    { value: 'kansas_city', label: 'Kansas City' },
  ],
  'Alabama': [
    { value: 'birmingham', label: 'Birmingham' },
    { value: 'huntsville', label: 'Huntsville' },
    { value: 'mobile', label: 'Mobile' },
    { value: 'montgomery', label: 'Montgomery' },
  ],
  'Arkansas': [
    { value: 'fayetteville', label: 'Fayetteville' },
    { value: 'fort_smith', label: 'Fort Smith' },
    { value: 'little_rock', label: 'Little Rock' },
    { value: 'rogers', label: 'Rogers' },
  ],
  'Alaska': [
    { value: 'anchorage', label: 'Anchorage' },
    { value: 'fairbanks', label: 'Fairbanks' },
  ],
  'California': [
    { value: 'los_angeles', label: 'Los Angeles' },
    { value: 'fresno', label: 'Fresno' },
    { value: 'san_diego', label: 'San Diego' },
    { value: 'san_francisco', label: 'San Francisco' },
    { value: 'san_jose', label: 'San Jose' },
  ],
  'Delaware': [
    { value: 'wilmington', label: 'Wilmington' },
  ],
  'Colorado': [
    { value: 'aurora', label: 'Aurora' },
    { value: 'colorado_springs', label: 'Colorado Springs' },
    { value: 'denver', label: 'Denver' },
    { value: 'fort_collins', label: 'Fort Collins' },
  ],
  'Arizona': [
    { value: 'chandler', label: 'Chandler' },
    { value: 'phoenix', label: 'Phoenix' },
    { value: 'tucson', label: 'Tucson' },
    { value: 'mesa', label: 'Mesa' },
  ],
  'Connecticut': [
    { value: 'stamford', label: 'Stamford' },
    { value: 'bridgeport', label: 'Bridgeport' },
    { value: 'new_haven', label: 'New Haven' },
    { value: 'hartford', label: 'Hartford' },
  ],
  'Florida': [
    { value: 'miami', label: 'Miami' },
    { value: 'jacksonville', label: 'Jacksonville' },
    { value: 'st_petersburg', label: 'St. Petersburg' },
    { value: 'tampa', label: 'Tampa' },
    { value: 'orlando', label: 'Orlando' },
  ],

  'Iowa': [
    { value: 'davenport', label: 'Davenport' },
    { value: 'cedar_rapids', label: 'Cedar Rapids' },
    { value: 'sioux_city', label: 'Sioux City' },
    { value: 'des_moines', label: 'Des Moines' },
  ],
  'Indiana': [
    { value: 'fort_wayne', label: 'Fort Wayne' },
    { value: 'south_bend', label: 'South Bend' },
    { value: 'evansville', label: 'Evansville' },
    { value: 'indianapolis', label: 'Indianapolis' },
  ],

  'Maine': [
    { value: 'bangor', label: 'Bangor' },
    { value: 'portland', label: 'Portland' },
    { value: 'lewaton', label: 'Lewaton' },
  ],
  'Montana': [
    { value: 'billings', label: 'Billings' },
  ],
  'Nevada': [
    { value: 'sparks', label: 'Sparks' },
    { value: 'las_vegas', label: 'Las Vegas' },
    { value: 'north_las_vegas', label: 'North Las Vegas' },
    { value: 'reno', label: 'Reno' },
  ],
  'New Hampshire': [
    { value: 'manchester', label: 'Manchester' },
    { value: 'nashua', label: 'Nashua' },
  ],
  'Nebraska': [
    { value: 'bellevue', label: 'Bellevue' },
    { value: 'grand_island', label: 'Grand Island' },
    { value: 'lincoln', label: 'Lincoln' },
    { value: 'omaha', label: 'Omaha' },
  ],
  'New Jersey': [
    { value: 'paterson', label: 'Paterson' },
    { value: 'jersey_city', label: 'Jersey City' },
    { value: 'newark', label: 'Newark' },
    { value: 'elizabeth', label: 'Elizabeth' },
  ],
  'North Carolina': [
    { value: 'greensboro', label: 'Greensboro' },
    { value: 'durham', label: 'Durham' },
    { value: 'charlotte', label: 'Charlotte' },
    { value: 'raleigh', label: 'Raleigh' },
  ],
  'New Mexico': [
    { value: 'santa_fe', label: 'Santa Fe' },
    { value: 'las_cruces', label: 'Las Cruces' },
    { value: 'rio_rancho', label: 'Rio Rancho' },
    { value: 'albuquerque', label: 'Albuquerque' },
  ],
  'Virginia': [
    { value: 'norfolk', label: 'Norfolk' },
    { value: 'chesapeake', label: 'Chesapeake' },
    { value: 'virginia_beach', label: 'Virginia Beach' },
    { value: 'richmond', label: 'Richmond' },
  ],
  'New York': [
    { value: 'syraucse', label: 'Syracuse' },
    { value: 'new_york_city', label: 'New York City' },
    { value: 'rochester', label: 'Rochester' },
    { value: 'buffalo', label: 'Buffalo' },
  ],
  'North Dakota': [
    { value: 'fargo', label: 'Fargo' },
    { value: 'bismarck', label: 'Bismarck' },
  ],
  'Oregon': [
    { value: 'eugene', label: 'Eugene' },
    { value: 'beaverton', label: 'Beaverton' },
    { value: 'salem', label: 'Salem' },
    { value: 'portland', label: 'Portland' },
  ],
  'Oklahoma': [
    { value: 'tulsa', label: 'Tulsa' },
    { value: 'oklahoma_city', label: 'Oklahoma City' },
    { value: 'norman', label: 'Norman' },
  ],
  'Kentucky': [
    { value: 'lexington', label: 'Lexington' },
    { value: 'louisville', label: 'Louisville' },
    { value: 'bowling_green', label: 'Bowling Green' },
  ],
  'Kansas': [
    { value: 'topeka', label: 'Topeka' },
    { value: 'overland_park', label: 'Overland Park' },
    { value: 'wichita', label: 'Wichita' },
    { value: 'olathe', label: 'Olathe' },
  ],
  'Louisiana': [
    { value: 'shreveport', label: 'Shreveport' },
    { value: 'lafayette', label: 'Lafayette' },
    { value: 'new_orleans', label: 'New Orleans' },
    { value: 'baton_rouge', label: 'Baton Rouge' },
  ],
  'Ohio': [
    { value: 'cleveland', label: 'Cleveland' },
    { value: 'toledo', label: 'Toledo' },
    { value: 'cincinnati', label: 'Cincinnati' },
    { value: 'columbus', label: 'Columbus' },
  ],

  'Rhode Island': [
    { value: 'providence', label: 'Providence' },
  ],
  'Pennsylvania': [
    { value: 'philadelphia', label: 'Philadelphia' },
    { value: 'pittsburgh', label: 'Pittsburgh' },
    { value: 'allentown', label: 'Allentown' },
    { value: 'eric', label: 'Erie' },
  ],
  'South Carolina': [
    { value: 'columbia', label: 'Columbia' },
    { value: 'charleston', label: 'Charleston' },
    { value: 'myrtle_beach', label: 'Myrtle Beach' },
    { value: 'greenville', label: 'Greenville' },
  ],
  'South Dakota': [
    { value: 'sioux_falls', label: 'Sioux Falls' },
    { value: 'rapid_city', label: 'Rapid City' },
  ],
  'Washington': [
    { value: 'seattle', label: 'Seattle' },
    { value: 'spokane', label: 'Spokane' },
    { value: 'tacoma', label: 'Tacoma' },
  ],
  'Wyoming': [
    { value: 'cheyenne', label: 'Cheyenne' },
  ],
  'West Virginia': [
    { value: 'charleston', label: 'Charleston' },
    { value: 'morgantown', label: 'Morgantown' },
    { value: 'huntington', label: 'Huntington' },
  ],
  'Wisconsin': [
    { value: 'milwaukee', label: 'Milwaukee' },
    { value: 'madison', label: 'Madison' },
    { value: 'green_bay', label: 'Green Bay' },
    { value: 'oshkosh', label: 'Oshkosh' },
  ],
  'Texas': [
    { value: 'austin', label: 'Austin' },
    { value: 'fort_worth', label: 'Fort Worth' },
    { value: 'houston', label: 'Houston' },
    { value: 'san_antonio', label: 'San Antonio' },
    { value: 'dallas', label: 'Dallas' },
  ],
  'Utah': [
    { value: 'west_valley_city', label: 'West Valley City' },
    { value: 'provo', label: 'Provo' },
    { value: 'salt_lake_city', label: 'Salt Lake City' },
  ],
  'Tennessee': [
    { value: 'chattanooga', label: 'Chattanooga' },
    { value: 'memphis', label: 'Memphis' },
    { value: 'nashville', label: 'Nashville' },
    { value: 'knoxville', label: 'Knoxville' },
  ],
  'Vermont': [
    { value: 'burlington', label: 'Burlington' },
  ],
};
// Customizable Area End
