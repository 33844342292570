import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Visibility, VisibilityOff } from "@material-ui/icons";
import * as React from 'react';
import * as Yup from "yup";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  showPassword: boolean;
  email: string
  password: string
  rememberMe: boolean
  formError: {
    email: string
    password: string
  };
  apiError: string | null;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  validateLoginAccountId: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      showPassword: false,
      email: '',
      password: '',
      rememberMe: false,
      formError: {
        email: '',
        password: '',
      },
      apiError: '',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (
      apiRequestCallId &&
      responseJson &&
      apiRequestCallId === this.validateLoginAccountId
    ) {
      if (responseJson?.errors && responseJson?.errors?.length !== 0) {
        const errorMessage = "Incorrect username or password. Please try again.";
        this.setState({
          formError: {
            email: '',
            password: '',
          },
          apiError: errorMessage,
        })
      }
      else {
        localStorage.setItem("email", this.state.email);
        localStorage.removeItem("fullName");
        this.setState({ apiError: null });
        this.handleNavigateAuth()
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const rememberedEmail = localStorage.getItem("rememberedEmail");
    const rememberedPassword = localStorage.getItem("rememberedPassword");
    if (rememberedEmail && rememberedPassword) {
      this.setState({
        email: rememberedEmail,
        password: rememberedPassword,
        rememberMe: true,
      });
    }
  }

  loginSchema = Yup.object({
    email: Yup.string()
      .email("Invalid e-mail")
      .required("Please enter your email address"),
    password: Yup.string()
      .required("Please enter your password"),
  });

  renderIconShowPassword = (show: boolean) => {
    return show ? (
      <VisibilityOff
        style={{
          width: "21.65px",
          height: "18.95px",
          color: "#94A3B8"
        }}
      />
    ) : (
      <Visibility
        style={{
          width: "21.65px",
          height: "18.95px",
          color: "#94A3B8"
        }}
      />
    );
  };

  submitLoginDetails = (email: string, password: string, rememberMe: boolean) => {
    if (rememberMe) {
      localStorage.setItem("rememberedEmail", email);
      localStorage.setItem("rememberedPassword", password);
    }

    let token = localStorage.getItem("token");
    const data = {
      type: 'email_account',
      attributes: {
        email: email,
        password: password,
      },
    }
    this.setState({
      email: email,
      password: password,
      rememberMe: rememberMe,
    })

    const header = {
      "Content-Type": 'application/json',
      token: token
    };

    const httpBody = {
      data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.validateLoginAccountId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_login/logins`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }

  handleNavigateSignup = () => {
    this.props.navigation.navigate("Signuplogin")
  }

  handleForgotBtn = () => {
    this.props.navigation.navigate("ForgotPassword")
  }

  handleClickShowPassword = () =>
    this.setState({
      showPassword: !this.state.showPassword
    });

  handleNavigateAuth = () => {
    this.props.navigation.navigate("TwoFactorAuth")
  }
  // Customizable Area End
}